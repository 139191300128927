import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import { motion } from "framer-motion";
import React from "react";

const slideInVariants = {
  hidden: { x: "-1vw", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};
export const CheckboxControl = ({ sx, label, checked, onChange }) => (
  <FormControlLabel
    sx={{ ...sx, width: "125px" }}
    control={
      <Checkbox color={"primary"} data-testid="checkbox" onChange={onChange} checked={checked} />
    }
    label={label}
  />
);

export const LabeledField = ({ label }) => (
  <MKBox display="flex" mb="2px">
    <MKTypography fontSize="16px" fontWeight="medium">
      {label}
    </MKTypography>
    <MKTypography fontSize="16px" fontWeight="medium" color="error" ml={1}>
      *
    </MKTypography>
  </MKBox>
);

export const FieldWrapper = ({ label, children }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={slideInVariants}
    transition={{ duration: "0.2", type: "tween" }}
  >
    <MKBox sx={{ mb: 1 }} data-testid={label}>
      <LabeledField label={label} />
      {children}
    </MKBox>
  </motion.div>
);
