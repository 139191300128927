import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import MKTypography from "../../MKTypography";
import MKBox from "../../MKBox";

import BusinessDetails from "../../../modals/Company/BusinessDetails";
import AddCompanyModal from "../../../modals/Company/AddCompany";
import SwitchAccountsModal from "../../../modals/Company/SwitchAccount";

import { useRecoilValue } from "recoil";
import { companyDetailsState } from "../../../atoms/company";
import {
  loadSelectedCompanyId,
  loadUserId,
  getGqlErrorMessage,
  removeSelectedCompanyId,
} from "../../../common";
import DeleteAccount from "../../../modals/Company/DeleteCompany";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { Avatar } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { removeToken } from "../../../tokenStore";

export const DELETE_COMPANY_QUERY = gql`
  mutation DeleteCompany($companyId: ID!) {
    deleteCompany(companyId: $companyId)
  }
`;

export default function PositionedMenu({ sx, onSwitchAccountsSubmit }) {
  const [deleteCompany] = useMutation(DELETE_COMPANY_QUERY);

  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [switchAccountsModalOpen, setSwitchAccountsModalOpen] = React.useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = React.useState(false);

  const companyDetails = useRecoilValue(companyDetailsState);

  const handleDeleteAccountModalOpen = () => {
    setDeleteAccountModalOpen(true);
    handleClose();
  };

  const handleDeleteAccountModalClose = () => {
    setDeleteAccountModalOpen(false);
  };

  const onDeleteAccountSubmit = (companyId) => {
    setSubmitLoading(true);
    const selectedCompanyId = loadSelectedCompanyId();
    deleteCompany({
      variables: {
        companyId: selectedCompanyId,
      },
      onCompleted: (data) => {
        //TODO: update UI if needed
        removeSelectedCompanyId();
        setSubmitLoading(false);
        navigate(`/select-company?company=${selectedCompanyId}`);
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to delete the company: " + getGqlErrorMessage(error));
        setSubmitLoading(false);
      },
    });
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
    handleClose();
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
    handleClose();
  };

  const handleAddModalClose = () => {
    setAddModalOpen(false);
  };

  const handleSwitchAccountsModalOpen = () => {
    handleClose();
    // setSwitchAccountsModalOpen(true);
    removeSelectedCompanyId();
    navigate(`/select-company`);
  };

  const handleSwitchAccountsModalClose = () => {
    setSwitchAccountsModalOpen(false);
  };

  const signOutUser = () => {
    const auth = getAuth();
    signOut(auth)
      .then(function () {
        console.log("Sign-out successful.");
        removeToken();
        navigate("/sign-in");
      })
      .catch(function (e) {
        console.log("Sign-out error: " + e);
        handleClose();
      });
  };

  return (
    <MKBox sx={{ position: "absolute", right: 0, top: { xs: "-2px", sm: 2 } }}>
      <Button
        data-testid={"menu-button"}
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mt: 0 }}
        endIcon={<KeyboardArrowDownIcon color="white" sx={{ pb: "2px" }} />}
      >
        <Avatar>
          {companyDetails?.logo ? (
            <img alt="logo" style={{ width: "40px", height: "40px" }} src={companyDetails.logo} />
          ) : (
            <BusinessIcon sx={{ width: "60%", height: "60%" }} />
          )}
        </Avatar>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ mt: "52px" }}
      >
        <MKBox display="flex" flexDirection="column" mb={2} px={2}>
          <MKTypography variant="p" fontWeight="bold" fontSize={16} width="230px">
            {companyDetails.name}
          </MKTypography>
          {companyDetails.number && (
            <MKTypography variant="p" fontSize={12} width="230px">
              Membership number: {companyDetails.number}
            </MKTypography>
          )}
        </MKBox>

        {companyDetails && companyDetails.length !== 0 && (
          <MenuItem onClick={handleEditModalOpen}>
            <ListItemIcon>
              <WorkOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "15px" }}
              primary="Business details"
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            const userId = loadUserId();
            navigate(`/user-access/user/${userId}`);
          }}
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Profile" />
        </MenuItem>
        <MenuItem onClick={signOutUser}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Log out" />
        </MenuItem>

        <Divider variant="middle" />

        {companyDetails?.id && (
          <MenuItem onClick={handleSwitchAccountsModalOpen}>
            <ListItemIcon>
              <CompareArrowsIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Switch account" />
          </MenuItem>
        )}

        {companyDetails?.id && (
          <MenuItem onClick={handleAddModalOpen}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "15px" }}
              primary="Open another account"
            />
          </MenuItem>
        )}

        {companyDetails?.id && (
          <MenuItem onClick={handleDeleteAccountModalOpen}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Delete account" />
          </MenuItem>
        )}
      </Menu>

      <BusinessDetails
        open={editModalOpen}
        handleClickOpen={handleEditModalOpen}
        handleClose={handleEditModalClose}
      />
      <AddCompanyModal
        open={addModalOpen}
        handleClickOpen={handleAddModalOpen}
        handleClose={handleAddModalClose}
      />
      <SwitchAccountsModal
        open={switchAccountsModalOpen}
        handleClose={handleSwitchAccountsModalClose}
        onSubmit={onSwitchAccountsSubmit}
      />
      <DeleteAccount
        open={deleteAccountModalOpen}
        handleClose={handleDeleteAccountModalClose}
        onSubmit={onDeleteAccountSubmit}
        loading={submitLoading}
      />
    </MKBox>
  );
}
