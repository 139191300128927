import { styled } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  borderRadius: "5px",
  ".MuiDataGrid-actionsCell": {
    margin: "auto",
  },
  "&.MuiDataGrid-columnHeaderTitle": {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    color: "rgba(0,0,0,0.85)",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#c0c0c0" : "#1d1d1d", // Darker background for light mode
  },

  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
    padding: 0,
    paddingLeft: "10px",
    fontSize: 12,
    display: "flex", // Use flexbox for alignment
    alignItems: "center", // Center vertically
    justifyContent: "flex-start", // Align text to the left
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    fontSize: "12px",
    paddingRight: "5px",
    color: "rgba(0,0,0,0.85)",
  },
  "& .MuiDataGrid-columnHeaders": {
    // Forced to use important since overriding inline styles
    maxHeight: "168px !important",
  },
  ".MuiTypography-root": {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    padding: "3px",
  },
  ".MuiDataGrid-detailPanelToggleCell": {
    margin: "auto",
  },
  ".cancelled-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#DCDCDC",
  },
  ".unpaid-theme .MuiDataGrid-cell .MuiBox-root": {
    background: "#BEBEBE",
  },
  ".MuiDataGrid-columnHeader": {
    background: "white",
  },
  ".MuiDataGrid-virtualScroller": {
    background: "white",
  },
  ".MuiDataGrid-footerContainer": {
    background: "white",
  },
  "& .MuiDataGrid-row.odd": {
    backgroundColor: "WhiteSmoke",
  },
  "& .MuiDataGrid-row.Mui-hovered": {
    backgroundColor: "Snow",
  },
  // ...customCheckbox(theme),
}));
export default StyledDataGrid;
