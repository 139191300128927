const COMPANY_ID_LOCAL_DB_KEY = "SELECTED_COMPANY_ID";
const USER_ID_LOCAL_DB_KEY = "USER_ID";


exports.loadUserId = () => {
  return localStorage.getItem(USER_ID_LOCAL_DB_KEY);
};

exports.saveUserId = (userId) => {
  localStorage.setItem(USER_ID_LOCAL_DB_KEY, userId);
};

exports.loadSelectedCompanyId = () => {
  return localStorage.getItem(COMPANY_ID_LOCAL_DB_KEY);
};

exports.saveSelectedCompanyId = (companyId) => {
  if (companyId) {
    localStorage.setItem(COMPANY_ID_LOCAL_DB_KEY, companyId);
  } else {
    localStorage.removeItem(COMPANY_ID_LOCAL_DB_KEY);
  }
};

exports.removeSelectedCompanyId = () => {
  return localStorage.removeItem(COMPANY_ID_LOCAL_DB_KEY);
};

exports.getGqlErrorMessage = (gqlError) => {
  const errorMessageArr = findFields(gqlError, "message");
  return errorMessageArr.join(",");
};

exports.stringsToLabels = (dbValues) => {
  return (
    dbValues?.map((value) => ({
      key: value,
      label: value,
    })) || []
  );
};

function findFields(obj, fieldName) {
  let results = [];

  function search(obj, fieldName) {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        // If the current property is an object, recursively search it
        search(obj[key], fieldName);
      } else if (key === fieldName) {
        // If the current property matches the fieldName, add it to the results
        results.push(obj[key]);
      }
    }
  }

  search(obj, fieldName);
  return results;
}
