import React, { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Avatar } from "@mui/material";
import MKBox from "./MKBox";
import MKButton from "./MKButton";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const cropWidth = 150;
const cropHeight = 150;

const imageWidth = 150;
const imageHeight = 150;

const initialCrop = {
  unit: "px",
  width: cropWidth,
  height: cropHeight,
  x: 0,
  y: 0,
};
const ImageCropper = ({ margin, handleImageSave, image }) => {
  const buttonRef = useRef();
  const canvasRef = useRef();
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(initialCrop);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [dataURL, setDataURL] = useState("");
  const [lastSavedImage, setLastSavedImage] = useState(null);
  const [open, setOpen] = useState(false);

  const validFileTypes = "image/jpeg, image/png, image/heic";

  useEffect(() => {
    setOpen(!!src);

    if (src) {
      makeClientCrop(crop);
    }
  }, [src]);

  useEffect(() => {
    if (image) {
      setCroppedImageUrl(image);
      setLastSavedImage(image);
    }
  }, []);

  function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error("Image load failed"));
    });
  }
  const maxFileSize = 5000000; // Max file size in bytes (5MB)
  const maxDimension = 3000; // Max width or height in pixels

  const onSelectFile = (e) => {
    buttonRef.current.blur();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > maxFileSize) {
        alert("File size too large. The maximum allowed size is 5MB.");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        // Check dimensions
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width > maxDimension || img.height > maxDimension) {
            alert("Image dimensions too large. The maximum allowed dimension is 3000x3000 pixels.");
            return;
          }
          setSrc(reader.result);
        };
        img.onerror = () => alert("An error occurred while reading the file.");
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = "";
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };
  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, "newFile.jpeg");
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    let loadedImage = image;

    // If image hasn't loaded properly reload image
    if (!loadedImage.naturalWidth) loadedImage = await loadImage(src);

    const canvas = canvasRef.current; // Use the existing ref
    if (!canvas) {
      console.error("Canvas ref is not set");
      return;
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = loadedImage.naturalWidth / imageWidth;
    const scaleY = loadedImage.naturalHeight / imageHeight;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      loadedImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const dataUrl = canvas.toDataURL("image/jpeg");
    setDataURL(dataUrl);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl); // changed this.fileUrl to fileUrl
          const newFileUrl = window.URL.createObjectURL(blob); // changed this.fileUrl to newFileUrl
          setFileUrl(newFileUrl); // setting new state value
          resolve(newFileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  const saveImage = () => {
    setSrc(null);
    handleImageSave(dataURL);
    setLastSavedImage(dataURL); // Update the last saved image
    setCrop(initialCrop);
  };

  const cancelImage = () => {
    // Revert to the last saved image instead of clearing it
    setCroppedImageUrl(lastSavedImage);
    setDataURL(lastSavedImage);
    setSrc(null);
    setCrop(initialCrop);
  };

  return (
    <MKBox display={"flex"} flexDirection="row" width={"122px"} m={margin} mb={2}>
      <MKBox display="flex" flexDirection={"column"} mt={1}>
        <canvas ref={canvasRef} style={{ display: "none" }} height={120} width={120} id="canvas" />
        <MKBox m={"auto"}>
          <Avatar sx={{ mb: 1, width: "120px", height: "120px" }}>
            {croppedImageUrl && (
              <img alt="Crop" style={{ width: "120px", height: "120px" }} src={croppedImageUrl} />
            )}
          </Avatar>
        </MKBox>
        <input
          style={{ display: "none" }}
          accept={validFileTypes} // Specify accepted file types
          id="actual-btn"
          type="file"
          onChange={onSelectFile}
        />
        <MKButton ref={buttonRef} color="primary" sx={{ p: 0, ml: "3px" }} htmlFor="actual-btn">
          <label
            style={{
              cursor: "pointer",
              width: "100%",
              height: "40px",
              paddingTop: "12px",
              fontSize: "12px",
              zIndex: "100",
            }}
            htmlFor="actual-btn"
          >
            Choose Image
          </label>
        </MKButton>
        <Dialog open={open} onClose={cancelImage}>
          <DialogTitle sx={{ textAlign: "center" }}>Crop Image</DialogTitle>
          <DialogContent>
            <MKBox width={"250px"}>
              <MKBox width="150px" margin={"auto"}>
                <ReactCrop
                  src={src}
                  crop={crop}
                  aspect={1}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  circularCrop={true}
                  style={{
                    height: "150px",
                    width: "150px",
                    marginTop: "15px",
                  }}
                >
                  <img
                    onLoad={(img) => makeClientCrop(crop)}
                    style={{ width: "150px", height: "150px" }}
                    ref={imageRef}
                    src={src}
                    alt="Uploaded preview"
                  />
                </ReactCrop>
              </MKBox>
            </MKBox>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", width: "220px", m: "auto" }}>
            <MKButton
              sx={{ width: "80px !important", pt: "11px" }}
              color="error"
              variant="gradient"
              onClick={cancelImage}
            >
              Cancel
            </MKButton>
            <MKButton
              sx={{ width: "80px !important", pt: "11px" }}
              color="info"
              variant="gradient"
              onClick={saveImage}
            >
              Save
            </MKButton>
          </DialogActions>
        </Dialog>
      </MKBox>
    </MKBox>
  );
};

export default ImageCropper;
