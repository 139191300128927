import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { getAuth, signOut } from "firebase/auth";

import { loadUserId } from "common";

import { gql, useQuery } from "@apollo/client";
import { loadSelectedCompanyId, saveSelectedCompanyId } from "../common";
import { companiesState, companyDetailsState, invoicesReceiptsState } from "../atoms/company";
import MKBox from "./MKBox";
import { Alert, CircularProgress } from "@mui/material";
import { roleState } from "atoms/company";
import MKButton from "./MKButton";
import ErrorMsg from "./ErrorMsg";

const extraLogs = true;

// transferred from TopBar

export const ON_LOAD_COMPANIES_QUERY = gql`
  query OnLoadQuery {
    companies {
      id
      name
      logo
      country
      type
      abn
      acn
      category
      description
      companySize
      companyAddress {
        address
        suburb
        state
        postcode
      }
      roles {
        name
      }
      tradingAddresses {
        address
        suburb
        state
        postcode
      }
    }
  }
`;

export const ON_LOAD_ROLES_QUERY = gql`
  query Query($companyId: ID!) {
    company(id: $companyId) {
      users {
        role {
          name
          description
          canUploadReceipts
          canUploadInvoices
          canAddUsers
          canEditUsers
          canAddRoles
          canEditRoles
          canAddVendors
          canViewHistory
          canViewUploadsForAllUsers
          canEditAppSettings
        }
        id
      }
    }
  }
`;

export const ON_LOAD_HISTORY_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    invoiceReceipts(companyId: $companyId) {
      amountInCents
      createdDate
      currency
      purchaseDate
      deptCode
      description
      dueDate
      paidFrom
      receiptOrInvoice
      submittedByName
      submittedByUID
      vendorName
      xeroBillId
      xeroBillAttachmentNames
      xeroSyncStatus
    }
  }
`;

const PermissionGate = ({ children }) => {
  const selectedCompanyId = loadSelectedCompanyId();
  let navigate = useNavigate();

  const {
    loading: isRolesQueryLoading,
    error: rolesQueryError,
    data: rolesQueryData,
  } = useQuery(ON_LOAD_ROLES_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: historyQueryLoading,
    error: historyQueryError,
    data: historyQueryData,
  } = useQuery(ON_LOAD_HISTORY_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
    fetchPolicy: "network-only",
  });

  let {
    loading: isCompaniesQueryLoading,
    error: companiesQueryError,
    data: companiesQueryData,
  } = useQuery(ON_LOAD_COMPANIES_QUERY, {
    fetchPolicy: "network-only",
  });

  const [userRole, setUserRole] = useState(null);
  const setRole = useSetRecoilState(roleState);

  const [error, setError] = useState(false);

  const setCompanyDetails = useSetRecoilState(companyDetailsState);

  const setCompanies = useSetRecoilState(companiesState);

  const setInvoiceReceipts = useSetRecoilState(invoicesReceiptsState);

  React.useEffect(() => {
    if (!isCompaniesQueryLoading && companiesQueryData) {
      extraLogs && console.log("useEffect  isCompaniesQueryLoading", isCompaniesQueryLoading);
      extraLogs && console.log("useEffect  companiesQueryData", companiesQueryData);
      checkAndSetCurrentCompany(companiesQueryData);
      transformAndSetCompanies(companiesQueryData);
    }
  }, [selectedCompanyId, isCompaniesQueryLoading, companiesQueryData]);

  React.useEffect(() => {
    if (!isRolesQueryLoading && rolesQueryData) {
      getRole(rolesQueryData);
    }
  }, [isRolesQueryLoading]);

  React.useEffect(() => {
    if (!historyQueryLoading && historyQueryData) {
      setInvoiceReceipts(historyQueryData.invoiceReceipts);
    }
  }, [historyQueryLoading]);
  const processAndGetCompanyId = (companiesQueryData) => {
    console.log("processAndGetCompanyId()");

    // Load selected company ID, if missing either save a new one or force user to choose
    let storedCompanyId = loadSelectedCompanyId();
    if (!storedCompanyId && companiesQueryData?.companies?.length === 1) {
      //  if there's only one GraphQL company then that must be the correct company, so update the browser storage
      let firstCompanyId = companiesQueryData.companies[0].id;
      saveSelectedCompanyId(firstCompanyId);
      storedCompanyId = firstCompanyId;
    }
    if (!storedCompanyId) {
      // still no stored company ID, so force user to choose company
      console.error("No selected company ID, redirecting to company select page");
      navigate("/select-company");
      return;
    }

    if (!(companiesQueryData?.companies.length > 0)) {
      // no graphQL company data, no point continuing
      console.log("storedCompanyId: " + storedCompanyId);
      console.log(
        "no graphQL company data, something is wrong, no point continuing: ",
        companiesQueryData
      );
      return storedCompanyId;
    }

    // try to check if the browser company ID matches the incoming graphQL company
    let matchedGraphQlCompany = companiesQueryData?.companies?.find(
      (company) => company?.id === storedCompanyId
    );

    if (!matchedGraphQlCompany) {
      console.log("storedCompanyId: " + storedCompanyId);
      console.log("matchedGraphQlCompany: " + matchedGraphQlCompany);
      console.log("no graphQL company data, no point continuing: ", companiesQueryData);
      console.error("Error -- no matchedGraphQlCompany, maybe not signed in?");
      setError(true);
    }
    return storedCompanyId;
  };

  const transformAndSetCompanies = (companiesQueryData) => {
    const filteredCompanies =
      companiesQueryData == null
        ? []
        : companiesQueryData.companies.map((company) => ({
            key: company.id,
            label: company.name,
            ...company,
          }));
    setCompanies(filteredCompanies);
  };
  const checkAndSetCurrentCompany = async (companiesQueryData) => {
    // TODO: get company details
    console.log("checkAndSetCurrentCompany()");
    const selectedCompanyId = await processAndGetCompanyId(companiesQueryData);
    console.info("selectedCompanyId: " + selectedCompanyId);
    const companyDetails =
      companiesQueryData == null
        ? []
        : companiesQueryData.companies.find((company) => company?.id == selectedCompanyId); // why are we just pulling the first company out?

    console.info("Selected Company Object: ", companyDetails);
    if (!companyDetails) {
      console.error("no matching company");
      return;
    }
    setCompanyDetails(companyDetails);
  };

  const getRole = (rolesQueryData) => {
    console.log("getRole rolesQueryData", rolesQueryData.company?.users);
    const userId = loadUserId();
    const user = rolesQueryData?.company?.users.find((user) => user.id === userId && userId);

    // console.log("USER LOADED: ", userId, rolesQueryData?.company?.users);
    if (rolesQueryError) {
      console.error(JSON.stringify(rolesQueryError));
    }

    if (rolesQueryError) {
      console.error(JSON.stringify(rolesQueryError));
    }

    if (!user?.role) {
      if (!userId) {
        console.error("couldn't find stored user");
      }
      console.log("USER localStorage ID:", userId, "selectedCompanyId:", selectedCompanyId);
      console.log("USER matched user:", JSON.stringify(user), "user.role:", user?.role);
      console.log("USER (rolesQueryData users):", JSON.stringify(rolesQueryData?.company?.users));
      console.error("couldn't find matching user with user role, redirecting user to another page");
      return setError(true);
    }

    setRole(user.role);
    setUserRole(user.role);
  };

  return (
    <>
      {!error && !userRole && !rolesQueryError && (
        <MKBox sx={{ height: "100vh", width: "100vw" }}>
          <MKBox display="flex" sx={{ position: "absolute", top: "25%", width: "100%", pt: 2 }}>
            <CircularProgress sx={{ margin: "auto" }} size={50} />
          </MKBox>
        </MKBox>
      )}
      {(error || (!userRole && rolesQueryError)) && (
        <MKBox>
          <ErrorMsg
            errorMsg={
              "An unknown error has occurred. Please contact your administrator for assistance. "
            }
          />
          <MKButton
            onClick={() => navigate("/sign-in")}
            variant="gradient"
            color={"info"}
            sx={{ mx: { xs: 2, sm: 3 } }}
          >
            Go back to sign in
          </MKButton>
        </MKBox>
      )}

      {!error && userRole && !rolesQueryError && children}
    </>
  );
};

export default PermissionGate;
