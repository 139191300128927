/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOM from "react-dom/client";
import { from, ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "App";
import "app.css";
import MuiXLicense from "./MuiXLicense";
import { getToken } from "./tokenStore";

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? `https://us-central1-reconcile-app.cloudfunctions.net/graphql`
      : "http://localhost:5001/reconcile-app/us-central1/graphql",
});

const authLink = setContext((operation, { headers }) => {
  const token = getToken(); // Get the token from tokenStore

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return { headers };
  }
});

const additiveLink = from([new RetryLink(), authLink, httpLink]);

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: additiveLink,
  // link: createUploadLink(),
  cache: new InMemoryCache(),
});

// Supported in React 18+
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
        <MuiXLicense />
      </BrowserRouter>
    </ApolloProvider>
  </RecoilRoot>
);
