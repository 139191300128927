import { Autocomplete, TextField } from "@mui/material";
import MKInput from "components/MKInput";
import React from "react";
import MKBox from "./MKBox";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AutoComplete = ({ data, value, handleFieldChange, error, freeSolo }) => {
  return (
    <Autocomplete
      data-testid="autocomplete"
      sx={{
        ".MuiOutlinedInput-root": {
          padding: " 1px !important",
          pl: "3px !important",
          fontSize: "0.75rem",
        },
        ".MuiAutocomplete-endAdornment": {
          pr: error ? 2 : 0,
        },
        //  height:"37px"
      }}
      // popupIcon={<ArrowDropDownIcon fontSize="small" sx={error && {mr:2, mt:"3px"}}/>}
      // clearIcon={<ClearIcon fontSize="small" />}
      options={data}
      value={value}
      disablePortal={false}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <MKInput
          onChange={(e) => freeSolo && handleFieldChange(e.target.value)}
          {...params}
          error={error}
          helperText={error && "Required field. "}
          autocomplete
        />
      )}
      onChange={(e, value) => {
        handleFieldChange(value);
      }}
      isOptionEqualToValue={(option, value) => {
        if (value === option && value !== "") return true;
      }}
      freeSolo={freeSolo || false}
    />
  );
};

export default AutoComplete;
