import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Loading from "../../components/Loading";

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import AddUserModal from "modals/Users/addUser";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { usersState, rolesState, roleState } from "../../atoms/company";

import { gql, useQuery, useMutation } from "@apollo/client";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { getGqlErrorMessage, loadSelectedCompanyId } from "../../common";
import { motion } from "framer-motion";
import PersonIcon from "@mui/icons-material/Person";
const slideInVariants = {
  hidden: { x: "-1vw", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};
export const ADD_USER_QUERY = gql`
  mutation AddUser($companyId: ID!, $input: UserInput!) {
    addUser(companyId: $companyId, input: $input) {
      id
      name
      users {
        id
        firstName
        lastName
        displayName
        email
        profileImageUrl
        role {
          id
          name
        }
      }
    }
  }
`;
function UsersList({ loading, handleRoleNumbers }) {
  let navigate = useNavigate();

  const selectedCompanyId = loadSelectedCompanyId();

  const [addUser] = useMutation(ADD_USER_QUERY);

  const role = useRecoilValue(roleState);
  const roles = useRecoilValue(rolesState);

  const users = useRecoilValue(usersState);
  const setUsers = useSetRecoilState(usersState);

  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [addLoading, setAddLoading] = useState(false);

  const handleOpen = () => {
    setErrorMessage("");
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onAddSubmit = (newUser) => {
    if (!role?.canAddUsers) return;

    setAddLoading(true);
    addUser({
      variables: {
        companyId: selectedCompanyId,
        input: {
          ...newUser,
          roleId: newUser.roleId.key,
        },
      },
      onCompleted: (data) => {
        setUsers(data.addUser.users);
        handleRoleNumbers(data.addUser.users);
        sendPasswordResetEmail(auth, newUser.email).then(() => {
          console.info("Password reset email sent to: " + newUser.email);
        });
        handleClose();
        setAddLoading(false);
      },
      onError: (error) => {
        console.error("Unable to add the user: " + getGqlErrorMessage(error).split(",")[0]);
        setErrorMessage("Unable to add the user: " + getGqlErrorMessage(error).split(",")[0]);
        setAddLoading(false);
      },
    });
  };

  return (
    <MKBox sx={{ px: { xs: 1, sm: 3 }, mt: 1 }}>
      {role?.canAddUsers && (
        <MKButton
          color="primary"
          sx={{ mb: { xs: 1, sm: 2 }, mt: { xs: 1, sm: 2 } }}
          onClick={handleOpen}
        >
          Add team member
        </MKButton>
      )}

      <MKBox
        sx={{
          background: "#fff",
          p: loading ? 4 : 2,
          borderRadius: "5px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
        mt={role?.canAddUsers ? 1 : 3}
      >
        {loading && <Loading size={40} />}
        {!loading && (
          <>
            <MKTypography
              sx={{
                pb: 1,
                color: "rgba(0, 0, 0, 0.6)",
                mb: 2,
                borderBottom: 1,
                borderColor: "divider",
                fontSize: 17,
              }}
            >
              Active members ({users?.length})
            </MKTypography>
            <List
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: { xs: "calc(100vh - 350px)", sm: "calc(100vh - 300px)" },
                overflow: "auto",
              }}
            >
              {users?.map((user) => {
                return (
                  <motion.div
                    key={user?.id}
                    initial="hidden"
                    animate="visible"
                    variants={slideInVariants}
                    transition={{ duration: "0.2", type: "tween" }}
                  >
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate(`/user-access/user/${user?.id}`)}
                        sx={{
                          "&:hover": { opacity: "0.9" },
                          p: 2,
                          marginBottom: "1rem",
                          borderRadius: "8px",
                          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          alignItems: { xs: "flex-start", sm: "center" },
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <ListItemAvatar sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}>
                          <Avatar sx={{ width: "60px", height: "60px" }}>
                            {user?.profileImageUrl?.length !== 0 ? (
                              <img
                                alt="Profile"
                                src={user.profileImageUrl}
                                style={{ width: "60px", height: "60px", objectFit: "cover" }}
                              />
                            ) : (
                              <PersonIcon sx={{ width: "80%", height: "80%" }} />
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            mr: { xs: "0 !important", sm: 10 },
                            overflow: "hidden", // Handle overflow
                            ".MuiListItemText-primary": {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                              fontSize: { xs: "1rem", sm: "1.25rem" },
                            },
                            ".MuiListItemText-secondary": {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                              fontSize: { xs: "0.9rem", sm: "1.25rem" },
                            },
                          }}
                          primary={user?.displayName}
                          secondary={user?.email}
                        />

                        <MKTypography
                          sx={{
                            flexShrink: 0,
                            marginRight: "1rem",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {user?.role?.name}
                        </MKTypography>
                      </ListItemButton>
                    </ListItem>
                  </motion.div>
                );
              })}
            </List>
          </>
        )}
      </MKBox>

      {roles && (
        <AddUserModal
          roles={roles}
          open={openModal}
          handleClose={handleClose}
          onSubmit={onAddSubmit}
          errorMessage={errorMessage}
          loading={addLoading}
        />
      )}
    </MKBox>
  );
}

export default UsersList;
