import React, { useState } from "react";
import Menu from "./Menu";
import { Paper } from "@mui/material";
import MKTypography from "components/MKTypography";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DeleteModal from "../../../modals/deleteModal";

import AddAccount from "../../../modals/Settings/addAccount";
import AddCurrency from "../../../modals/Settings/addCurrency";

const List = ({
  onEditCurrencySubmit,
  onEditAccountSubmit,
  account,
  selectedItem,
  handleSelectedItemChange,
  onDeleteSubmit,
  items,
  loading,
}) => {
  const [localAnchorEl, setLocalAnchorEl] = useState(null);
  const [localOpenMenu, setLocalOpenMenu] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openEditAccountModal, setOpenEditAccountModal] = useState(false);
  const [openEditCurrencyModal, setOpenEditCurrencyModal] = useState(false);

  const handleOpenEditAccountModal = () => {
    setOpenEditAccountModal(true);
    handleCloseMenu();
  };

  const handleCloseEditAccountModal = () => {
    setOpenEditAccountModal(false);
  };

  const handleOpenEditCurrencyModal = () => {
    setOpenEditCurrencyModal(true);
    handleCloseMenu();
  };

  const handleCloseEditCurrencyModal = () => {
    setOpenEditCurrencyModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    handleCloseMenu();
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenMenu = (e, item) => {
    setLocalAnchorEl(e.currentTarget);
    setLocalOpenMenu(true);
    handleSelectedItemChange(item);
  };

  const handleCloseMenu = () => {
    setLocalOpenMenu(false);
    setLocalAnchorEl(null);
  };

  return (
    <Paper
      sx={{
        p: 1,
        minHeight: "100px",
        maxHeight: "250px",
        overflowY: "auto",
        pt: 2,
      }}
      elevation={2}
    >
      {items.length === 0 && (
        <MKTypography variant="p" ml={1} fontSize={16}>
          No Items Found...{" "}
        </MKTypography>
      )}
      {items.length !== 0 &&
        items.map((item) => (
          <Paper
            key={item.key}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "75%",
              mb: 1,
              ml: 1,
              borderRadius: "10px",
              "&.MuiPaper-root:hover": {
                background: "#fdfdfd",
              },
            }}
            elevation={2}
          >
            <MKTypography
              sx={{
                fontSize: "15px",
                pt: "8px",
                ml: "10px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {item.label}
            </MKTypography>
            <IconButton data-testid={item.label} onClick={(e) => handleOpenMenu(e, item)}>
              <MoreHorizIcon />
            </IconButton>
          </Paper>
        ))}

      <Menu
        handleOpenEditModal={account ? handleOpenEditAccountModal : handleOpenEditCurrencyModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        anchorEl={localAnchorEl}
        open={localOpenMenu}
        handleClose={handleCloseMenu}
      />

      <DeleteModal
        value={selectedItem?.label}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        handleDelete={onDeleteSubmit}
        message="Are you sure you want to delete this?"
        loading={loading}
      />

      <AddCurrency
        currencyToEdit={selectedItem}
        open={openEditCurrencyModal}
        handleClose={handleCloseEditCurrencyModal}
        onSubmit={onEditCurrencySubmit}
        loading={loading}
      />

      <AddAccount
        accountToEdit={selectedItem}
        open={openEditAccountModal}
        handleClose={handleCloseEditAccountModal}
        onSubmit={onEditAccountSubmit}
        loading={loading}
      />
    </Paper>
  );
};
export default List;
