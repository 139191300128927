import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import MKInput from "../../../components/MKInput";

const ANZSICSearchSelect = ({ handleChange }) => {
  const [searchTerms, setSearchTerms] = useState("");
  const [options, setOptions] = useState([]);

  const uri = "https://industrycoder.abs.gov.au/pocc?s=";

  useEffect(() => {
    if (searchTerms.length < 3) {
      // Minimum length to start search
      setOptions([]);
      return;
    }

    const fetchOptions = async () => {
      try {
        const response = await fetch(uri + searchTerms);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOptions(data.slice(0, 8));
      } catch (error) {
        window.alert("Error: " + error.message);
      }
    };

    const debounceFetch = setTimeout(fetchOptions, 500); // debounce for 500ms
    return () => clearTimeout(debounceFetch);
  }, [searchTerms]);

  return (
    <Autocomplete
      sx={{
        ".MuiOutlinedInput-root": {
          padding: " 1px !important",
          pl: "3px !important",
          fontSize: "0.75rem",
        },
      }}
      onChange={(e, value) => {
        handleChange(value);
      }}
      options={options.map((option) => `${option.code}: ${option.unparsedText}`)}
      renderInput={(params) => (
        <MKInput
          {...params}
          onChange={(e) => setSearchTerms(e.target.value)}
          helperText={"Minimum number of characters to start search is 3. "}
        />
      )}
    />
  );
};

export default ANZSICSearchSelect;
