import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setToken } from "./tokenStore";

export const AuthContext = createContext({
  isAuthInitialized: false,
  currentUser: null,
  idToken: null,
});

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthInitialized: false,
    currentUser: null,
    idToken: null,
  });

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        let checkAndUpdateToken = async () => {
          if (user) {
            const token = await user.getIdToken(); // also refreshes the token if it's about to expire
            setToken(token);

            setAuthState({
              isAuthInitialized: true,
              currentUser: user,
              idToken: token,
            });
          }
        }
        console.log("SignedIn")
        await checkAndUpdateToken()
        setInterval(()=> checkAndUpdateToken(), 5 * 60 * 1000) // keep checking
      } else {
        console.log("SignedOut")
        setAuthState({
          isAuthInitialized: true,
          currentUser: null,
          idToken: null,
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};
