import MKBox from "./MKBox";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Loading = ({ size, sx }) => {
  return (
    <MKBox data-testid="loading" display="flex" sx={{ ...sx }}>
      <CircularProgress size={size} />
    </MKBox>
  );
};
export default Loading;
