import React, { useEffect, useState } from "react";

import { Alert, Dialog, DialogActions, TextField } from "@mui/material";

import MKButton from "components/MKButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";
import AutoComplete from "components/Autocomplete";

import ImageCropper from "../../components/ImageCropper";
import MKBox from "../../components/MKBox";

const EditUserModal = ({
  roles,
  userDetails,
  open,
  handleClose,
  onSubmit,
  errorMessage,
  loading,
  disableRoleInput,
}) => {
  const [user, setUser] = useState(userDetails);

  const [role, setRole] = useState(null);

  const [image, setImage] = useState("");

  const [inputError, setInputError] = useState({
    firstName: false,
    lastName: false,
    role: false,
  });

  useEffect(() => {
    getRole();
    setInputError({
      firstName: false,
      lastName: false,
      role: false,
    });
    setImage(userDetails.profileImageUrl);
  }, [open]);

  const handleChange = (input) => (e) => {
    setUser({ ...user, [input]: e.target.value });
  };

  const handleRoleChange = (value) => {
    setRole(value);
  };

  const getRole = () => {
    // TODO: get all roles
    // set initial role for selector
    roles.forEach((role) => {
      if (role?.key === userDetails?.role?.id) {
        setRole({ key: userDetails?.role?.id, label: role?.label });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {
      firstName: !user.firstName,
      lastName: !user.lastName,
      role: !role?.key,
    };

    setInputError(errors);
    if (!user.firstName || !user.lastName || !role?.key) {
      return;
    }
    if (image) {
      setUser({ ...user, profileImage: image });
    }
    onSubmit({ ...user, profileImage: image, roleId: role });
  };

  const handleImageSave = (dataURL) => {
    setImage(dataURL);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableRestoreFocus={true}>
        <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>Edit User</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Container>
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2, ".MuiAlert-icon": { pt: "10px" } }}>
                {errorMessage}.
              </Alert>
            )}
            <ImageCropper margin="auto" handleImageSave={handleImageSave} image={image} />

            <MKTypography variant="p">First name</MKTypography>
            <TextField
              data-testid={"firstName"}
              fullWidth
              size="small"
              sx={{ mb: 2, mt: "2px" }}
              value={user.firstName}
              onChange={handleChange("firstName")}
              error={inputError.firstName}
              helperText={inputError.firstName && "Required field. "}
            />

            <MKTypography variant="p">Last name</MKTypography>
            <TextField
              data-testid={"lastName"}
              fullWidth
              size="small"
              sx={{ mb: 2, mt: "2px" }}
              value={user.lastName}
              onChange={handleChange("lastName")}
              error={inputError.lastName}
              helperText={inputError.lastName && "Required field. "}
            />
            <MKTypography variant="p">Role{disableRoleInput && ":  "} </MKTypography>
            {disableRoleInput && <MKTypography variant="p">{role?.label}</MKTypography>}
            {roles && !disableRoleInput && (
              <AutoComplete
                data={roles}
                value={role}
                handleFieldChange={handleRoleChange}
                error={inputError.role}
              />
            )}
          </Container>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton onClick={handleSubmit} disabled={loading}>
            Submit
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUserModal;
