import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import IconSelector from "../../components/IconSelector";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InfoIcon from "@mui/icons-material/Info";

const iconMap = {
  Manager: BusinessCenterIcon,
  Developer: ComputerIcon,
  Support: SupportAgentIcon,
  Engineer: EngineeringIcon,
  Employee: AccountCircleIcon,
  Logistics: LocalShippingIcon,
  Maintenance: CleaningServicesIcon,
  Retail: StorefrontIcon,
};
const EditRole = ({ roleDetails, open, handleClose, onSubmit, loading }) => {
  const [role, setRole] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    setRole(roleDetails);
    const IconComponent = iconMap[roleDetails?.roleImage];

    if (roleDetails?.roleImage) {
      setSelectedIcon({
        name: roleDetails?.roleImage,
        icon: <IconComponent fontSize="large" />,
      });
    }

    setNameError(false);
  }, [open]);

  const handleSelectedIcon = (name, IconComponent) => {
    setSelectedIcon({ name: name, icon: <IconComponent fontSize="large" /> });
  };
  const handleChange = (fieldName, isSwitch) => (e) => {
    const value = isSwitch ? e.target.checked : e.target.value;

    setRole({ ...role, [fieldName]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError(!role.name);
    if (role.name) {
      onSubmit({ ...role, roleImage: selectedIcon.name });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableRestoreFocus={true}
        onKeyUp={(e) => {
          const ENTER = 13;

          if (e.keyCode === ENTER) {
            handleSubmit(e);
          }
        }}
      >
        <DialogTitle sx={{ fontSize: "24px", textAlign: "center", mt: 1, mb: 2 }}>
          Edit Role
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          {role && (
            <Container>
              <MKBox component="form" role="form">
                <IconSelector selectedIcon={selectedIcon} handleSelectedIcon={handleSelectedIcon} />

                <MKTypography variant="p">Role name</MKTypography>
                <MKInput
                  data-testid={"name"}
                  fullWidth
                  size="small"
                  sx={{ mb: 2, mt: "2px" }}
                  value={role.name}
                  onChange={handleChange("name")}
                  error={nameError}
                  helperText={nameError && "Required field. "}
                />

                <MKTypography variant="p">Description</MKTypography>
                <MKInput
                  fullWidth
                  size="small"
                  sx={{ mb: 2, mt: "2px" }}
                  value={role.description}
                  onChange={handleChange("description")}
                />

                <MKTypography fontWeight="bold">Upload</MKTypography>
                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Receipts
                    </MKTypography>
                    <Switch
                      checked={role.canUploadReceipts}
                      onChange={handleChange("canUploadReceipts", true)}
                    />
                  </MKBox>
                  <Divider sx={{ m: 0 }} />
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Invoices
                    </MKTypography>
                    <Switch
                      checked={role.canUploadInvoices}
                      onChange={handleChange("canUploadInvoices", true)}
                    />
                  </MKBox>
                </MKBox>
                <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
                  Users
                </MKTypography>
                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Add user
                    </MKTypography>
                    <Switch
                      checked={role.canAddUsers}
                      onChange={handleChange("canAddUsers", true)}
                    />
                  </MKBox>
                  <Divider sx={{ m: 0, color: "black" }} />
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Edit/Delete user
                    </MKTypography>
                    <Switch
                      checked={role.canEditUsers}
                      onChange={handleChange("canEditUsers", true)}
                    />
                  </MKBox>
                </MKBox>
                <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
                  Roles
                </MKTypography>
                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Add role
                    </MKTypography>
                    <Switch
                      checked={role.canAddRoles}
                      onChange={handleChange("canAddRoles", true)}
                    />
                  </MKBox>
                  <Divider sx={{ m: 0, color: "black" }} />
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Edit/Delete role
                    </MKTypography>
                    <Switch
                      checked={role.canEditRoles}
                      onChange={handleChange("canEditRoles", true)}
                    />
                  </MKBox>
                </MKBox>
                <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
                  Form
                </MKTypography>
                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Create vendor
                    </MKTypography>
                    <Switch
                      checked={role.canAddVendors}
                      onChange={handleChange("canAddVendors", true)}
                    />
                  </MKBox>
                </MKBox>
                <MKBox display={"flex"} mt={1}>
                  <MKTypography fontWeight="bold">All Invoices/Receipts</MKTypography>
                  <Tooltip
                    placement="right"
                    describeChild
                    title="Ability to view uploads (receipts & invoices) for all users. If turned off, the user can only see their own uploads. "
                  >
                    <InfoIcon fontSize={"medium"} sx={{ mt: "5px", ml: 1 }} />
                  </Tooltip>
                </MKBox>

                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      View upload history for all users
                    </MKTypography>

                    <Switch
                      checked={role?.canViewUploadsForAllUsers}
                      onChange={handleChange("canViewUploadsForAllUsers", true)}
                    />
                  </MKBox>
                </MKBox>
                <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
                  Settings
                </MKTypography>
                <MKBox sx={{ ml: 1 }}>
                  <MKBox display="flex" justifyContent="space-between">
                    <MKTypography variant="p" pt="5px">
                      Can edit settings
                    </MKTypography>
                    <Switch
                      checked={role?.canEditAppSettings}
                      onChange={handleChange("canEditAppSettings", true)}
                    />
                  </MKBox>
                </MKBox>
              </MKBox>
            </Container>
          )}
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton disabled={loading} type="form" onClick={handleSubmit}>
            Submit
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditRole;
