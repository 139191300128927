import React, { useEffect, useState } from "react";

import { Dialog, DialogActions, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MKIconButton from "../../components/MKIconButton";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, TextField } from "@mui/material";
import MKTypography from "components/MKTypography";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import IconSelector from "../../components/IconSelector";
const AddRole = ({ open, handleClose, onSubmit, loading }) => {
  const [role, setRole] = useState({
    name: "",
    description: "",
    canUploadReceipts: true,
    canUploadInvoices: true,
    canAddUsers: false,
    canEditUsers: false,
    canAddRoles: false,
    canEditRoles: false,
    canAddVendors: true,
    canViewUploadsForAllUsers: false,
    canEditAppSettings: false,
  });

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    setNameError(false);
    setSelectedIcon(null);
  }, [open]);

  const handleSelectedIcon = (name, IconComponent) => {
    setSelectedIcon({
      name: name,
      icon: <IconComponent fontSize="large" />,
    });
  };

  const handleChange = (fieldName, isSwitch) => (e) => {
    const value = isSwitch ? e.target.checked : e.target.value;
    setRole({ ...role, [fieldName]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError(!role?.name);

    if (role?.name) {
      onSubmit({ ...role, roleImage: selectedIcon?.name });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        disableRestoreFocus={true}
        onKeyUp={(e) => {
          const ENTER = 13;

          if (e.keyCode === ENTER) {
            handleSubmit(e);
          }
        }}
      >
        <DialogTitle sx={{ textAlign: "center", mt: 1, mb: 2 }}>Add Role</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Container>
            <IconSelector selectedIcon={selectedIcon} handleSelectedIcon={handleSelectedIcon} />
            <MKTypography variant="p">Role name</MKTypography>
            <TextField
              data-testid="name"
              fullWidth
              size="small"
              sx={{ mb: 2, mt: "2px" }}
              value={role?.name}
              onChange={handleChange("name")}
              error={nameError}
              helperText={nameError && "Required field. "}
            />

            <MKTypography variant="p">Description</MKTypography>
            <TextField
              data-testid="description"
              fullWidth
              size="small"
              sx={{ mb: 2, mt: "2px" }}
              value={role?.description}
              onChange={handleChange("description")}
            />

            <MKTypography fontWeight="bold">Upload</MKTypography>
            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Receipts
                </MKTypography>
                <Switch
                  checked={role?.canUploadReceipts}
                  onChange={handleChange("canUploadReceipts", true)}
                />
              </MKBox>
              <Divider sx={{ m: 0 }} />
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Invoices
                </MKTypography>
                <Switch
                  checked={role?.canUploadInvoices}
                  onChange={handleChange("canUploadInvoices", true)}
                />
              </MKBox>
            </MKBox>
            <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
              Users
            </MKTypography>
            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Add user
                </MKTypography>
                <Switch checked={role?.canAddUsers} onChange={handleChange("canAddUsers", true)} />
              </MKBox>
              <Divider sx={{ m: 0, color: "black" }} />
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Edit/Delete user
                </MKTypography>
                <Switch
                  checked={role?.canEditUsers}
                  onChange={handleChange("canEditUsers", true)}
                />
              </MKBox>
            </MKBox>
            <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
              Roles
            </MKTypography>
            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Add role
                </MKTypography>
                <Switch checked={role?.canAddRoles} onChange={handleChange("canAddRoles", true)} />
              </MKBox>
              <Divider sx={{ m: 0, color: "black" }} />
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Edit/Delete role
                </MKTypography>
                <Switch
                  checked={role?.canEditRoles}
                  onChange={handleChange("canEditRoles", true)}
                />
              </MKBox>
            </MKBox>
            <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
              Form
            </MKTypography>
            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Create vendor
                </MKTypography>
                <Switch
                  checked={role?.canAddVendors}
                  onChange={handleChange("canAddVendors", true)}
                />
              </MKBox>
            </MKBox>
            <MKBox display={"flex"} mt={1}>
              <MKTypography fontWeight="bold">History</MKTypography>
              <Tooltip
                placement="right"
                describeChild
                title="Ability to view uploads (receipts & invoices) for all users. If turned off, the user can only see their own uploads. "
              >
                <InfoIcon fontSize={"medium"} sx={{ mt: "5px", ml: 1 }} />
              </Tooltip>
            </MKBox>

            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  View Upload History for All Users
                </MKTypography>

                <Switch
                  checked={role?.canViewUploadsForAllUsers}
                  onChange={handleChange("canViewUploadsForAllUsers", true)}
                />
              </MKBox>
            </MKBox>
            <MKTypography fontWeight="bold" sx={{ mt: 1 }}>
              Settings
            </MKTypography>
            <MKBox sx={{ ml: 1 }}>
              <MKBox display="flex" justifyContent="space-between">
                <MKTypography variant="p" pt="5px">
                  Can edit settings
                </MKTypography>
                <Switch
                  checked={role?.canEditAppSettings}
                  onChange={handleChange("canEditAppSettings", true)}
                />
              </MKBox>
            </MKBox>
          </Container>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton onClick={handleSubmit} disabled={loading}>
            Submit
          </MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRole;
