/* == Firebase Configuration File == */

// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// Firebase Configurations
const firebaseConfig = {
  apiKey: "AIzaSyDtj48nx-mFfjnXzAvRWvxukRmzcqqHaP0",
  authDomain: "reconcile-app.firebaseapp.com",
  databaseURL: "https://reconcile-app-default-rtdb.firebaseio.com",
  projectId: "reconcile-app",
  storageBucket: "reconcile-app.appspot.com",
  messagingSenderId: "14495857322",
  appId: "1:14495857322:web:693a957ee42766bf8d6ed3",
  measurementId: "G-48SESVC3GE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence);

// auth.onAuthStateChanged((user) => {
//   if (user) {
//     console.info("onAuthStateChanged:: User is signed in", user);
//   } else {
//     console.info("onAuthStateChanged:: User is signed out");
//   }
// });

export const storage = getStorage();
if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
}

export default app;
