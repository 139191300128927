import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Box, AppBar, Toolbar, CssBaseline } from "@mui/material";
// import CompanyCard from "./components/CompanyCard";
import MKButton from "../../components/MKButton";
import { gql, useQuery } from "@apollo/client";
import AutoComplete from "./components/Autocomplete";
import Loading from "../../components/Loading";
import { loadSelectedCompanyId, saveSelectedCompanyId } from "../../common";
import MKBox from "../../components/MKBox";

const ON_LOAD_ROLES_QUERY = gql`
  query Query {
    myRoles {
      companyId
      roleId
      roleName
    }
    companies {
      id
      name
      logo
    }
  }
`;

const SelectCompanyPage = () => {
  let navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState(null);

  let currentUser = getAuth().currentUser
  console.log("SelectCompany Page - current logged-in user? " + !!currentUser + " " + currentUser?.email)

  const { loading, error, data } = useQuery(ON_LOAD_ROLES_QUERY, {
    fetchPolicy: "network-only",
  });

  const [companies, setCompanies] = useState([]);

  const [companyError, setCompanyError] = useState(false);

  useEffect(() => {
    const company = loadSelectedCompanyId();
    if (company) navigate("/upload");
    if (!loading) {
      getCompanies();
    }
  }, [loading]);

  // un-comment when data is correct
  const getCompanies = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const deleteCompany = queryParams.get("company");

    const allCompanies = data?.companies;
    const companyRoles = data?.myRoles;

    let userCompanies = allCompanies
      ?.filter((company) =>
        companyRoles?.some(
          (role) => role.companyId === company.id && deleteCompany !== role.companyId
        )
      )
      .map((company) => {
        // Find the first role that matches the company ID
        const matchedRole = companyRoles?.find((role) => role.companyId === company.id);

        // Extract the role name
        const roleName = matchedRole?.roleName;

        return {
          key: company.id,
          label: company.name,
          role: roleName, // include the role name
          logo: company.logo,
        };
      });

    setCompanies(userCompanies);
  };
  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  const onSubmit = () => {
    if (!selectedCompany) return setCompanyError(true);
    setCompanyError(false);
    saveSelectedCompanyId(selectedCompany.key);
    navigate("/upload");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f9fa",
      }}
    >
      <CssBaseline />

      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          color: "#333333",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <img style={{ width: "100px" }} src={require("../../assets/images/TrueSightLogo.png")} />
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          minWidth: "350px",
          maxWidth: "600px !important",
        }}
      >
        <Box
          sx={{
            mt: 3,
            mb: 4,
            p: 4,
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: 18, sm: 24 }, fontWeight: "bold", marginBottom: 1 }}
          >
            Which company would you like to use?{" "}
          </Typography>
          <Typography
            variant={"body1"}
            color="textSecondary"
            paragraph
            sx={{ mb: 2, fontSize: { xs: 15, sm: 18 } }}
          >
            Please select a company to proceed
          </Typography>
          {error && (
            <>
              <Typography color="error" variant="h5">
                An unknown error has occurred.
              </Typography>
              <MKButton
                sx={{ mt: 2, mb: 4 }}
                variant="outlined"
                color="error"
                onClick={() => navigate("/sign-in")}
              >
                You may need to sign in
              </MKButton>
            </>
          )}
          <MKBox container spacing={4}>
            {loading && (
              <MKBox width={"45px"} m={"auto"}>
                <Loading />
              </MKBox>
            )}
            {!loading && companies && (
              <MKBox m="auto" mt={4} width={{ xs: "300px", sm: "85%" }}>
                <AutoComplete
                  data={companies}
                  value={selectedCompany}
                  handleFieldChange={handleCompanySelect}
                  error={companyError}
                />
              </MKBox>
            )}
          </MKBox>
          {!loading && companies && (
            <MKButton
              onClick={onSubmit}
              sx={{ mt: 4, width: "100%", maxWidth: "150px" }}
              color="info"
              variant="gradient"
            >
              Submit
            </MKButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default SelectCompanyPage;
