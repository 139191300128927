import React, { useEffect, useState } from "react";
import MainContainer from "components/MainContainer/MainContainer";
import MKBox from "components/MKBox";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useRecoilValue } from "recoil";
import { roleState, openState, invoicesReceiptsState } from "../../atoms/company";
import { useNavigate } from "react-router-dom";

import StyledDataGrid from "../../components/DataGrid";
import CloseIcon from "@mui/icons-material/Close";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import DoneIcon from "@mui/icons-material/Done";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
function History() {
  const auth = getAuth();
  let navigate = useNavigate();

  const invoicesReceipts = useRecoilValue(invoicesReceiptsState);

  const [rows, setRows] = useState(null);
  const sideBarOpen = useRecoilValue(openState);

  const [columns, setColumns] = useState([
    { field: "countryCode", headerName: "Country Code", flex: 1 },
    { field: "createdDate", headerName: "Created", flex: 2 },
    { field: "receiptOrInvoice", headerName: "Receipt/Invoice", flex: 2 },
    { field: "purchaseDate", headerName: "Date Purchased", flex: 2 },
    { field: "vendorName", headerName: "Vendor Name", flex: 2 },
    { field: "description", headerName: "Description of purchase", flex: 5 },
    { field: "currency", headerName: "Currency", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 2 },
    { field: "dueDate", headerName: "Due Date", flex: 2 },
    { field: "paidFrom", headerName: "Expense Label", flex: 2 },
    { field: "submittedByName", headerName: "Submitted By", flex: 3 },

    {
      field: "xeroSyncStatus",
      headerName: "Xero Synced",
      flex: 1,
      renderCell: (params) => {
        switch (params.row.xeroSyncStatus) {
          case "FAILED":
            return <CloseIcon fontSize={"medium"} color={"error"} title={params.row.uploadErrorMessage || ""} />;
          case "OK":
            return <DoneIcon fontSize={"medium"} color={"success"} />;
          case "ATTACHMENT_FAILED":
            return <PriorityHighIcon fontSize={"medium"} color={"error"} title={"Invoice uploaded but file attachment failed. " + (params.row.uploadErrorMessage || "")} />;
          default:
            return <CloudSyncIcon fontSize={"medium"} />;
        }
      },
    },

    { field: "xeroBillId", headerName: "Xero Bill ID", flex: 2 },
    { field: "xeroBillAttachmentNames", headerName: "Xero Bill Attachments", flex: 2 },
  ]);

  const role = useRecoilValue(roleState);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    countryCode: false,
    createdDate: true,
    receiptOrInvoice: true,
    purchaseDate: true,
    vendorName: true,
    description: true,
    currency: true,
    amount: true,
    dueDate: true,
    paidFrom: true,
    submittedByName: true,
    xeroSyncStatus: true,
    xeroBillId: false,
    xeroBillAttachmentNames: true,
  });

  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail("");
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (invoicesReceipts) getRows(invoicesReceipts, userEmail);
  }, [invoicesReceipts, userEmail]);

  const getRows = (invoicesReceipts, userEmail) => {
    // Helper function to format date
    const formatDate = (dateString) => {
      if (!dateString) return null;
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;
    };

    const filteredReceipts = invoicesReceipts.filter((invoiceReceipt) => {
      // If role.canViewHistory is false, filter by userEmail
      if (!role.canViewHistory) {
        return invoiceReceipt.submittedByName === userEmail;
      }
      return true; // If role.canViewHistory is true, include all rows
    })
      .sort((a, b) => (a.createdDate > b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0));

    const formattedRows = filteredReceipts.map((invoiceReceipt, index) => ({
      ...invoiceReceipt,
      id: index,
      dueDate: formatDate(invoiceReceipt.dueDate),
      amount: `$${(invoiceReceipt.amountInCents / 100).toFixed(2)}`,
      purchaseDate: formatDate(invoiceReceipt.purchaseDate),
      accountUsed: invoiceReceipt.submittedByName,
      createdDate: formatDate(invoiceReceipt.createdDate),
    }));

    setRows(formattedRows);
  };

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <MainContainer title="Upload History">
      <MKBox minHeight="75vh" width="100%" sx={{ overflowX: "auto" }}>
        <MKBox
          sx={{
            width: {
              xs: "2000px",
              sm: sideBarOpen ? "calc(100vw - 270px)" : "calc(100vw - 65px)",
            },
            px: { xs: 0, sm: 2 },
            pt: { xs: 0, sm: 2 },
            height: { xs: "calc(100vh - 140px)", sm: "calc(100vh - 85px)" },
          }}
        >
          {rows && (
            <StyledDataGrid
              hideFooter
              rows={rows}
              columns={columns}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              columnVisibilityModel={columnVisibilityModel}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
            />
          )}
        </MKBox>
      </MKBox>
    </MainContainer>
  );
}

export default History;
