import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText, Divider } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { Container } from "@mui/material";
import MKIconButton from "../../../../components/MKIconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKAlert from "../../../../components/MKAlert";

const TradingAddress = ({
  state,
  openNewTradingAddressPage,
  openEditTradingAddressPage,
  deleteAddress,
  errors,
  prefillTradingAddress,
}) => {
  return (
    <>
      <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>
        Primary trading addresses
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", mb: 2, mx: 1 }}>
          Your trading address is usually the place you work every day. If the business has multiple
          trading addresses, add as many as possible.
        </DialogContentText>
        {errors?.length !== 0 && (
          <MKAlert data-testid="error" color="error">
            A trading address is required.
          </MKAlert>
        )}
        <Container>
          {state?.tradingAddresses?.length !== 0 &&
            state.tradingAddresses.map((address, i) => (
              <MKBox key={i} mb={1}>
                <MKBox
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="rgba(52, 71, 103, 0.2) 1px solid"
                >
                  <MKTypography fontWeight="medium" variant="p" pb="2px" pt="3px">
                    Address line {i + 1}
                  </MKTypography>
                  <MKBox>
                    {i > 0 && (
                      <MKButton
                        color="error"
                        variant="text"
                        sx={{
                          minHeight: "5px !important",
                          pb: "0px !important",
                          pt: "3px !important",
                        }}
                        onClick={() => deleteAddress(i)}
                      >
                        Delete
                      </MKButton>
                    )}
                    <MKIconButton
                      data-testid={"tradingAddress"}
                      onClick={() => openEditTradingAddressPage(address, i)}
                      sx={{
                        minHeight: "10px !important",
                        pb: "0px !important",
                        pt: "3px !important",
                      }}
                    >
                      <BorderColorIcon fontSize={"small"} color="primary" />
                    </MKIconButton>
                  </MKBox>
                </MKBox>

                <MKBox display="flex" flexDirection="column" mt={1}>
                  <MKTypography fontSize="15px" variant="p">
                    {address.address}
                  </MKTypography>
                  <MKTypography fontSize="15px" variant="p">
                    {address.suburb}
                  </MKTypography>
                  <MKTypography fontSize="15px" variant="p">
                    {address.state}
                  </MKTypography>
                  <MKTypography fontSize="15px" variant="p">
                    {address.postcode}
                  </MKTypography>
                </MKBox>
              </MKBox>
            ))}
          <MKBox
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
          >
            {prefillTradingAddress && (
              <MKButton
                variant="gradient"
                color="secondary"
                sx={{ mt: 2, fontSize: "13px", "margin-right": "2px" }}
                onClick={prefillTradingAddress}
              >
                Same as registered address
              </MKButton>
            )}
            <MKButton
              color="primary"
              sx={{ mt: 2, fontSize: "13px", "margin-left": "3px", "margin-right": "3px" }}
              onClick={openNewTradingAddressPage}
            >
              Add different trading address
            </MKButton>
          </MKBox>
        </Container>
      </DialogContent>
    </>
  );
};

export default TradingAddress;
