import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ItemMenu = ({ handleOpenEditModal, handleOpenDeleteModal, anchorEl, open, handleClose }) => {
  if (!anchorEl) return;
  return (
    <Menu
      anchorEl={anchorEl}
      id="basic-menu"
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleOpenEditModal}>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Edit" />
      </MenuItem>
      <MenuItem onClick={handleOpenDeleteModal}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ fontSize: "15px" }} primary="Delete" />
      </MenuItem>
    </Menu>
  );
};
export default ItemMenu;
