import React, { useEffect, useState } from "react";

import { Dialog, DialogActions } from "@mui/material";
import MKButton from "components/MKButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogPaper: {
    width: "100%", // Adjust the width as needed,
    maxWidth: "500px",
  },
});
const AddAccountModal = ({ open, handleClose, onSubmit, accountToEdit, loading }) => {
  const classes = useStyles();

  const [account, setAccount] = useState({
    name: "",
  });

  const [error, setError] = useState({
    name: false,
  });

  useEffect(() => {
    if (accountToEdit) setAccount({ name: accountToEdit.label });
    else
      setAccount({
        name: "",
      });
  }, [open]);

  const handleChange = (input) => (e) => {
    setAccount({ ...account, [input]: e.target.value });
  };

  const handleSubmit = (e) => {
    const errors = {
      name: false,
    };

    !account.name ? (errors.name = true) : (errors.name = false);

    setError(errors);
    if (!account.name) {
      return;
    }
    onSubmit(account);
    handleClose();
  };

  return (
    <Dialog
      onKeyUp={(e) => {
        const ENTER = 13;

        if (e.keyCode === ENTER) {
          handleSubmit(e);
        }
      }}
      disableRestoreFocus={true}
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>{accountToEdit ? "Edit form option" : "Add new form option"}</DialogTitle>
      <DialogContent sx={{ pt: "5px !important" }}>
        <MKBox width={{ xs: "100%", sm: "450px" }}>
          <MKInput
            label={"Name"}
            autoFocus
            fullWidth
            size="small"
            value={account.name}
            onChange={handleChange("name")}
            error={error.name}
            helperText={error.name && "Required field. "}
          />
        </MKBox>
      </DialogContent>
      <DialogActions>
        <MKButton onClick={handleClose}>Cancel</MKButton>
        <MKButton disabled={loading} sx={{ pl: 0, pr: 0 }} onClick={handleSubmit}>
          Submit
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;
