const camelCaseToHumanReadable = (str, acronyms = ["ACN", "ABN"]) => {
  if (acronyms.includes(str.toUpperCase())) {
    return str.toUpperCase();
  }

  return str
    .replace(/([A-Z])/g, " $1") // insert a space before each uppercase letter
    .toLowerCase(); // convert all to lowercase
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

exports.validateState = (state, fieldsToValidate) => {
  if (!fieldsToValidate) return [];

  const errorFields = [];
  fieldsToValidate?.forEach((field) => {
    if (!state[field]) {
      errorFields.push(field);
    } else if (field === "description" && state[field].length < 5) {
      errorFields.push("descriptionLength");
    }
  });

  // Check for trading addresses if it's included in the fields to validate
  if (fieldsToValidate.includes("tradingAddresses") && state?.tradingAddresses?.length === 0) {
    errorFields.push("tradingAddresses");
  }

  return errorFields;
};
