import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const FilePreviewModal = ({ files, selectedIndex, open, onClose, onNavigate }) => {
  const file = files[selectedIndex];

  const filePreview = (file) => {
    const fileType = file.type;
    const fileURL = URL.createObjectURL(file);

    if (fileType.startsWith("image/")) {
      return <img src={fileURL} alt={file.name} style={{ height: "350px" }} />;
    } else if (fileType.startsWith("audio/")) {
      return <audio controls src={fileURL} />;
    } else if (fileType.startsWith("video/")) {
      return <video controls src={fileURL} style={{ width: "100px", height: "auto" }} />;
    } else if (fileType === "application/pdf") {
      return <iframe src={fileURL} style={{ height: "600px", width: "800px" }} />;
    } else {
      // Default case for other file types
      return <div>File: {file.name}</div>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="file-preview-dialog-title"
      sx={{ ".MuiPaper-root": { maxWidth: "1000px" } }}
    >
      <DialogTitle id="file-preview-dialog-title">
        File Preview - {file ? file.name : ""}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={() => onNavigate(-1)} disabled={selectedIndex <= 0}>
            <ArrowBackIcon />
          </IconButton>
          {file && filePreview(file)}
          <IconButton onClick={() => onNavigate(1)} disabled={selectedIndex >= files.length - 1}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal;
