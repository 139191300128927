/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";

// Authentication pages components
import BasicLayout from "../layout";

// Images
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { Alert } from "@mui/material";
import {
  saveUserId,
  loadSelectedCompanyId,
  loadUserId,
  removeSelectedCompanyId,
} from "../../../common";
import { onAuthStateChanged } from "firebase/auth";

function SignInBasic() {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    if (signedIn) {
      let selectedCompanyId = loadSelectedCompanyId()
      if (selectedCompanyId) {
        navigate("/upload"); // Navigate to the main page once signed in if company is selected
      } else {
        navigate("/select-company"); // Navigate to select company
      }
    }
  }, [signedIn, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    email ? setEmailError(false) : setEmailError(true);
    password ? setPasswordError(false) : setPasswordError(true);

    if (!email || !password) {
      return setLoading(false);
    }
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const oldUserID = loadUserId();
        if (oldUserID !== userCredential.user.uid) removeSelectedCompanyId();
        saveUserId(userCredential.user.uid);
        setSignedIn(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: "200px", maxWidth: "500px", m: "auto" }}>
        <MKBox
          bgColor="secondary"
          borderRadius="lg"
          coloredShadow="secondary"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MKTypography>
        </MKBox>
        {error && (
          <Alert
            severity="error"
            sx={{
              // mb: 1,
              // mt: 1,
              // mx: { xs: 2, sm: 1 },
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              ".MuiAlert-icon": { pt: "10px" },
              mx: 3,
              mb: 0,
              mt: 2,
            }}
          >
            Login failed, your username or password is wrong.{" "}
          </Alert>
        )}
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
                onClick={() => navigate("/forgot-password")}
                sx={{ cursor: "pointer", userSelect: "none", ml: 2, textDecoration: "underline" }}
              >
                Forgot Password
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1}>
              <MKButton type="submit" color="secondary" fullWidth onClick={onSubmit}>
                {loading ? <CircularProgress size={20} /> : "Sign in"}
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default SignInBasic;
