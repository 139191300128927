import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, TextField } from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
const countryList = require("country-list");
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import AnzsicCodeSelect from "../AnzsicCodeSelect";

const countries = countryList.getNames();

const types = [
  "Club or Society",
  "Company",
  "Individual",
  "Not for Profit",
  "Partnership",
  "Self Managed Superannuation Fund",
  "Sole Trader",
  "Superannuation Fund",
  "Trust",
];

const Details = ({ state, handleChange, errors, handleAnzsicCodeChange }) => {
  return (
    <>
      <DialogTitle sx={{ textAlign: "center", mt: 1, mb: 2 }}>
        Fill in your business details
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Container>
          <MKTypography variant="p">Australian Business Number (ABN)</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.ABN}
            onChange={handleChange("ABN")}
            error={errors.includes("ABN")}
            helperText={errors.includes("ABN") && "Required field. "}
          />
          <MKTypography variant="p">Country</MKTypography>
          <FormControl fullWidth sx={{ mb: 2, mt: "2px" }} error={errors.includes("country")}>
            <Select
              value={state.country}
              onChange={handleChange("country")}
              sx={{ p: "0.75rem", pl: "0" }}
              input={<OutlinedInput endAdornment={<ArrowDropDownIcon />} />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            {errors.includes("country") && <FormHelperText>Required field. </FormHelperText>}
          </FormControl>

          <MKTypography variant="p">Registered business name</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.name}
            onChange={handleChange("name")}
            error={errors.includes("name")}
            helperText={errors.includes("name") && "Required field. "}
          />
          <MKTypography variant="p">Company Type</MKTypography>

          <FormControl fullWidth sx={{ mb: 2, mt: "2px" }} error={errors.includes("type")}>
            <Select
              value={state.type}
              onChange={handleChange("type")}
              sx={{ p: "0.75rem", pl: "0" }}
              input={<OutlinedInput endAdornment={<ArrowDropDownIcon />} />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {types.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            {errors.includes("type") && <FormHelperText>Required field. </FormHelperText>}
          </FormControl>

          <MKTypography variant="p">Australian Company Number (ACN)</MKTypography>
          <MKInput
            fullWidth
            size="small"
            value={state.ACN}
            onChange={handleChange("ACN")}
            error={errors.includes("ACN")}
            helperText={errors.includes("ACN") && "Required field. "}
            sx={{ mb: 2 }}
          />
          <MKTypography variant="p">Anzsic Code</MKTypography>

          <AnzsicCodeSelect handleChange={handleAnzsicCodeChange} />
        </Container>
      </DialogContent>
    </>
  );
};

export default Details;
