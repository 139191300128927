import React, { useEffect, useState } from "react";
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "../layout";
import Card from "@mui/material/Card";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKAlert from "../../../components/MKAlert";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { UPLOAD_RECEIPT_QUERY } from "../../Upload";
import MKSnackbar from "../../../components/MKSnackbar";
import { Alert, AppBar, Container, Toolbar } from "@mui/material";
import logo from "../../../assets/images/truesight-badge-orange.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const alertStyle = {
  mx: 3,
  mb: 0,
  mt: 2,
  ".MuiAlert-icon": { pt: "9px" },
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

const ForgotPassword = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(""); // State to store the generated link

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let mode = queryParams.get("mode");
    let oobCode = queryParams.get("oobCode");
    let iframeUrl = `https://dashboard.truesight.au/__/auth/action?mode=${mode}&apiKey=AIzaSyDtj48nx-mFfjnXzAvRWvxukRmzcqqHaP0&lang=en&&oobCode=${oobCode}`;
    if (mode && oobCode) {
      setGeneratedLink(iframeUrl);
      setLoading(false);
    } else setLoading(false);
  }, []);

  const onSubmit = async (e) => {
    e.target.blur();
    e.preventDefault();
    setEmailError(false);
    setLoading(true); // Start loading
    setSuccess(false);
    setError(false);
    if (!email) {
      setEmailError(true);
      setLoading(false); // Stop loading if email is empty
      return;
    }

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      // Handle success - maybe set a state to show a success message
      console.log("Password reset email sent successfully");
      setSuccess(true);
    } catch (error) {
      // Handle errors
      console.error("Error sending password reset email: ", error);
      setError(true);
    }
    setLoading(false);
    setEmail("");
  };
  return (
    <>
      {!loading && generatedLink && (
        <MKBox>
          <AppBar position="static" color={"secondary"} sx={{ px: 3 }}>
            <Toolbar disableGutters>
              <img style={{ width: "30px" }} src={logo} alt="logo" />
              <MKBox sx={{ flexGrow: 1 }} />
              <MKButton onClick={() => navigate("/sign-in")}>Sign In</MKButton>
            </Toolbar>
          </AppBar>
          <iframe
            src={generatedLink}
            title="Reset Password"
            style={{ width: "100%", height: "400px" }}
          />
        </MKBox>
      )}

      {!loading && !generatedLink && (
        <BasicLayout image={bgImage}>
          <Card sx={{ minWidth: "200px", maxWidth: "450px", m: "auto" }}>
            <MKBox
              bgColor="secondary"
              borderRadius="lg"
              coloredShadow="secondary"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="h4" fontWeight="medium" color="white">
                Forgot Password
              </MKTypography>
            </MKBox>
            {error && (
              <Alert color="error" sx={alertStyle}>
                Failed to send password reset email. Please check the email address.
              </Alert>
            )}
            {success && (
              <Alert color="success" sx={alertStyle}>
                A password reset email has been sent
              </Alert>
            )}
            <MKBox pt={4} pb={3} px={3}>
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput
                    type="email"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                    helperText={emailError && "Required field. "}
                  />
                </MKBox>
                <MKBox display="flex" alignItems="center" ml={-1}>
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="secondary"
                    onClick={() => navigate("/sign-in")}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                      ml: 2,
                      textDecoration: "underline",
                    }}
                  >
                    Sign in
                  </MKTypography>
                </MKBox>
                <MKBox mt={3} mb={1}>
                  <MKButton type="submit" color="secondary" fullWidth onClick={onSubmit}>
                    {loading ? <CircularProgress size={20} /> : "Submit"}
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Card>
        </BasicLayout>
      )}
    </>
  );
};
export default ForgotPassword;
