import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

export default function MKDatePicker({ error, size, value, onChange }) {
  const handleContainerClick = (event) => {
    const buttonElement = event.currentTarget.querySelector(".MuiButtonBase-root");
    const inputElement = event.currentTarget.querySelector("input");

    if (buttonElement) {
      inputElement.blur();
      buttonElement.click();
    }
  };

  return (
    <div onClick={handleContainerClick}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: "100%" }}
          size={size}
          value={value}
          onChange={onChange}
          format="DD/MM/YYYY"
          renderInput={(params) => (
            <TextField {...params} error={error} helperText={error ? "Required field." : ""} />
          )}
          onError={(err) => console.log(err)}
        />
      </LocalizationProvider>
    </div>
  );
}
