import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MKButton from "components/MKButton";
import { Avatar, Container, Grid, ListItemText } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";

const ListItem = ({ primary, secondary }) => {
  return (
    <Grid item xs={8}>
      <ListItemText
        primaryTypographyProps={{ fontSize: "15px" }}
        secondaryTypographyProps={{ fontSize: "15px" }}
        primary={primary}
        secondary={secondary}
      />
    </Grid>
  );
};
export default function Summary({ errorMessages, companyDetails, open, handleClose }) {
  return (
    <>
      <DialogTitle sx={{ textAlign: "center" }}>
        Please confirm these details are correct
      </DialogTitle>
      <DialogContent>
        <Container>
          {errorMessages.length > 0 && (
            <MKAlert data-testid="error" color="error">
              {errorMessages.toString()}.{" "}
            </MKAlert>
          )}

          <MKTypography sx={{ borderBottom: "black 1px solid", mt: 2, mb: 1 }}>
            Business information
          </MKTypography>

          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <ListItem primary="Registered business name" secondary={companyDetails.name} />
              <ListItem primary="Category" secondary={companyDetails.category} />
              <ListItem primary="Company Type" secondary={companyDetails.type} />
              <ListItem primary="Australian Business Number (ABN)" secondary={companyDetails.ABN} />
              <ListItem
                primary="Please describe your business in more detail"
                secondary={companyDetails.description}
              />
              <ListItem primary="Australian Company Number (ACN)" secondary={companyDetails.ACN} />
              <ListItem primary="Size of your business" secondary={companyDetails.companySize} />
            </Grid>
          </MKBox>
          <MKTypography sx={{ borderBottom: "black 1px solid", mt: 2, mb: 1 }}>
            Registered address
          </MKTypography>
          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <ListItem primary="Address" secondary={companyDetails.address} />
              <ListItem primary="Suburb" secondary={companyDetails.suburb} />
              <ListItem primary="State" secondary={companyDetails.state} />
              <ListItem primary="Postcode" secondary={companyDetails.postcode} />
              <ListItem primary="Country" secondary={companyDetails.country} />
            </Grid>
          </MKBox>
          <MKTypography sx={{ borderBottom: "black 1px solid", mt: 2, mb: 1 }}>
            Trading address
          </MKTypography>
          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              {companyDetails?.tradingAddresses.map((address, i) => (
                <ListItem
                  key={i}
                  primary={`Address ${i + 1}`}
                  secondary={`${address.address}, ${address.suburb}, ${address.state}, ${address.postcode}`}
                />
              ))}
            </Grid>
          </MKBox>
        </Container>
      </DialogContent>
    </>
  );
}
