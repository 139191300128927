import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip, useMediaQuery } from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RestoreIcon from "@mui/icons-material/Restore";
import GroupIcon from "@mui/icons-material/Group";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import { useEffect, useState } from "react";
import ImageIcon from "@mui/icons-material/Image";
import PositionedMenu from "./components/Menu";
import { companyDetailsState } from "../../atoms/company";
import { saveSelectedCompanyId } from "../../common";
import { gql, useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { roleState, openState } from "atoms/company";

// import expandedLogo from "../../assets/images/Truesight-left-aligned-logo.png";
import collapsedLogo from "../../assets/images/truesight-badge-orange.png";
export const ON_LOAD_QUERY = gql`
  query OnLoadQuery {
    companies {
      id
      name
      country
      type
      abn
      acn
      category
      description
      companySize
      companyAddress {
        address
        suburb
        state
        postcode
      }
      tradingAddresses {
        address
        suburb
        state
        postcode
      }
    }
  }
`;

const drawerWidth = 270;

const links = [
  // {
  //   header: "Receipt - Invoices Upload",
  //   url: "/upload",
  //   icon: <ReceiptLongIcon fontSize={"large"} />,
  // },
  // {
  //   header: "Upload History",
  //   url: "/history",
  //   icon: <RestoreIcon fontSize={"large"} />,
  // },
  {
    header: "User Access",
    url: "/user-access",
    icon: <GroupIcon fontSize={"large"} />,
  },
  // {
  //   header: "Settings",
  //   url: "/settings",
  //   icon: <SettingsSuggestIcon fontSize={"large"} />,
  // },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow:
    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Link = ({ open, link, navigate }) => {
  return (
    <Tooltip
      title={!open && link.header}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            ml: "5px !important",
          },
        },
      }}
    >
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 40,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            mt: 1,
          }}
          onClick={() => {
            navigate(link.url);
          }}
        >
          <ListItemIcon
            sx={{
              ".MuiSvgIcon-root": {
                fontSize: "12px",
                color:
                  window.location.pathname === link.url ||
                  (link.url === "/upload" && window.location.pathname === "/")
                    ? "#FFA041"
                    : "#0B2C3F",
              },
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {link.icon}
          </ListItemIcon>
          <ListItemText
            primary={link.header}
            primaryTypographyProps={{
              style: { fontSize: "14px", fontWeight: 500 },
            }}
            sx={{ pt: "2px", opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

export default function SideNav({ children, title, overflow }) {
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  let navigate = useNavigate();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY);

  const companyDetails = useRecoilValue(companyDetailsState);
  const role = useRecoilValue(roleState);

  const open = useRecoilValue(openState);
  const setOpen = useSetRecoilState(openState);

  useEffect(() => {
    if (open === null) {
      if (window.matchMedia("(min-width: 768px)").matches) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, []);
  const onSwitchAccountsSubmit = (company) => {
    // TODO: Switch account
    // set new id in local storage
    saveSelectedCompanyId(company.id);
    window.location.reload();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", p: 0 }}>
      <CssBaseline />
      <AppBar color="secondary" position="fixed" open={open} sx={{ height: "63px" }}>
        <Toolbar sx={{ justifyContent: "space-between", pr: "10px !important" }}>
          <Box display={"flex"}>
            <Box
              sx={{
                ...(open && { display: "none" }),
                height: "35px",
                position: "relative",
                left: { xs: "0px", sm: "-4px" },
                cursor: "pointer", // Add this line
              }}
              onClick={() => navigate("/upload")} // Add this line
            >
              <img src={collapsedLogo} style={{ height: "100%" }} />
            </Box>

            <IconButton
              color="white"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { display: "none" }),
                position: "relative",
                left: "5px",
              }}
            >
              <ChevronRightIcon color={"white"} />
            </IconButton>

            <Typography
              mt={open ? "0" : { xs: "10px", sm: "7px" }}
              variant="h5"
              noWrap
              component="div"
              fontSize={{ xs: "16px", sm: "20px" }}
              color={"white !important"}
            >
              {title}
            </Typography>
          </Box>
          <Box display={"flex"}>
            {companyDetails && <PositionedMenu onSwitchAccountsSubmit={onSwitchAccountsSubmit} />}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={"permanent"}
        open={open}
        sx={{
          ...(matchesSM &&
            open && {
              position: "absolute",
              zIndex: theme.zIndex.drawer + 1,
              width: "100vw",
              height: "100vh",
              ".MuiDrawer-paper": {
                width: "100%",
              },
            }),
        }}
      >
        <DrawerHeader
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #e1e1e1",
            background: "#0B2C3F",
          }}
        >
          <img
            style={{ width: "160px", marginLeft: "16px" }}
            src={
              "https://assets-global.website-files.com/622acfa2d9180ab1c8266ce0/622adfdc541898b557b0a513_Truesight-Logo.svg"
            }
            alt="logo"
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon color={"white"} />
            ) : (
              <ChevronLeftIcon color={"white"} />
            )}
          </IconButton>
        </DrawerHeader>

        <List
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box>
            {(role?.canUploadInvoices || role?.canUploadReceipts) && (
              <Link
                key="Receipt / Invoices Upload"
                open={open}
                link={{
                  header: "Receipt / Invoices Upload",
                  url: "/upload",
                  icon: <ReceiptLongIcon fontSize="large" />,
                }}
                navigate={navigate}
              />
            )}
            <Link
              key="Upload History"
              open={open}
              link={{
                header: "Upload History",
                url: "/history",
                icon: <RestoreIcon fontSize="large" />,
              }}
              navigate={navigate}
            />
            {role?.canEditAppSettings && (
              <Link
                key="Settings"
                open={open}
                link={{
                  header: "Settings",
                  url: "/settings",
                  icon: <SettingsSuggestIcon fontSize={"large"} />,
                }}
                navigate={navigate}
              />
            )}
            {links.map((link) => (
              <Link
                key={link.url}
                open={open}
                link={{
                  header: link.header,
                  url: link.url,
                  icon: link.icon,
                }}
                navigate={navigate}
              />
            ))}
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: {
            xs: "57px",
            sm: "64px",
          },
          height: "100%",
          // background: "#eee",
          overflowX: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
