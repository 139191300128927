import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { companiesState, companyDetailsState } from "../../../atoms/company";

import AutoComplete from "../../../pages/SelectCompany/components/Autocomplete";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogPaper: {
    width: "100%", // Adjust the width as needed,
    maxWidth: "500px",
  },
});
const SwitchAccount = ({ open, handleClose, onSubmit }) => {
  const classes = useStyles();

  const [selectedCompany, setSelectedCompany] = useState(null);

  const companies = useRecoilValue(companiesState);
  const companyDetails = useRecoilValue(companyDetailsState);

  const [options, setOptions] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSelectedCompany(companies.filter((company) => company.name === companyDetails.name)[0]);
    setOptions(
      companies.map((company) => {
        return {
          key: company.id,
          label: company.name,
          // role: company.role, // include the role name
          logo: company.logo,
        };
      })
    );
  }, []);

  useEffect(() => {
    setError(false);
  }, [open]);

  const handleChange = (value) => {
    setSelectedCompany(value);
  };

  const handleOnKeyUp = (e) => {
    const ENTER = 13;

    if (e.keyCode === ENTER) {
      if (selectedCompany) onSubmit(selectedCompany);
    }
  };

  const handleSubmit = () => {
    if (!selectedCompany) return setError(true);
    setError(false);
    handleClose();
    onSubmit(selectedCompany);
  };

  return (
    <MKBox>
      <Dialog
        open={open}
        onClose={handleClose}
        onKeyUp={handleOnKeyUp}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle>Switch accounts</DialogTitle>
        <DialogContent width={{ xs: "100%", sm: "450px" }}>
          <MKBox display="flex" flexDirection="column" mt={1} maxWidth={"450px"}>
            {options && (
              <MKBox>
                <AutoComplete
                  data={options}
                  value={selectedCompany}
                  handleFieldChange={handleChange}
                  error={error}
                />
              </MKBox>
            )}
          </MKBox>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton onClick={handleSubmit} sx={{ pl: 0, pr: 0 }}>
            Submit
          </MKButton>
        </DialogActions>
      </Dialog>
    </MKBox>
  );
};

export default SwitchAccount;
