import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const MKIconButton = ({ sx, children, onClick }) => {
  // const StyledIconButton = styled(IconButton)(({ theme }) => ({
  //   ...sx,
  //   background: "rgb(172 172 172)",
  //   "&:hover": {
  //     backgroundColor: "rgb(172 172 172)",
  //     boxShadow: "none",
  //   },
  //   "&:active": {
  //     boxShadow: "none",
  //     // backgroundColor: '#3c52b2',
  //   },
  // }));

  return (
    <IconButton sx={{ ...sx }} variant="gradient" color={"info"} onClick={onClick}>
      {children}
    </IconButton>
  );
};
export default MKIconButton;
