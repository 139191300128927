import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import MKButton from "components/MKButton";

import BusinessDetails from "./businessDetails";
import NewTradingAddress from "../AddCompany/sections/addTradingAddress";
import EditTradingAddress from "../AddCompany/sections/editTradingAddress";
import TradingAddress from "../AddCompany/sections/tradingAddress";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { companyDetailsState } from "../../../atoms/company";
import { gql, useMutation } from "@apollo/client";
import { loadSelectedCompanyId } from "../../../common";
import ErrorMsg from "../../../components/ErrorMsg";

const UPDATE_COMPANY_QUERY = gql`
  mutation UpdateCompany($companyId: ID!, $input: CompanyInput!) {
    updateCompany(companyId: $companyId, input: $input) {
      abn
      acn
      category
      companyAddress {
        address
        postcode
        state
        suburb
      }
      companySize
      country
      description
      id
      logo
      name
      tradingAddresses {
        address
        postcode
        state
        suburb
      }
      type
    }
  }
`;

export default function FormDialog({ open, handleClose }) {
  const [updateCompany] = useMutation(UPDATE_COMPANY_QUERY);

  const [page, setPage] = useState(0);

  const [registeredAddress, setRegisteredAddress] = useState({});

  const [tradingAddresses, setTradingAddresses] = useState([]);
  const [tradingAddressState, setTradingAddressState] = useState({
    address: "",
    suburb: "",
    state: "",
    postcode: "",
  });

  const companyDetails = useRecoilValue(companyDetailsState);
  const setCompanyDetails = useSetRecoilState(companyDetailsState);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    setTradingAddresses(companyDetails?.tradingAddresses);

    setRegisteredAddress({
      country: companyDetails?.country,
      state: companyDetails.companyAddress?.state,
      address: companyDetails.companyAddress?.address,
      postcode: companyDetails.companyAddress?.postcode,
      suburb: companyDetails.companyAddress?.suburb,
    });
    if (open) setPage(0);
    setError("");
  }, [open]);

  const handleRegisteredAddressChange = (input) => (e) => {
    setRegisteredAddress({ ...registeredAddress, [input]: e.target.value });
  };

  const handleTradingAddressChange = (input) => (e) => {
    setTradingAddressState({ ...tradingAddressState, [input]: e.target.value });
  };

  const openEditRegisteredAddress = () => {
    setRegisteredAddress({
      country: companyDetails?.country,
      state: companyDetails?.companyAddress.state,
      address: companyDetails?.companyAddress.address,
      postcode: companyDetails?.companyAddress.postcode,
      suburb: companyDetails?.companyAddress.suburb,
    });
    setPage(1);
  };

  const openBusinessDetails = () => {
    setPage(0);
  };

  const openEditTradingAddresses = () => {
    setPage(2);
  };

  const onRegisteredAddressUpdate = () => {
    // TODO: update company details with new registered address
    setError("");
    setSubmitLoading(true);

    updateCompany({
      variables: {
        companyId: loadSelectedCompanyId(),
        input: {
          companyAddress: {
            state: registeredAddress.state,
            address: registeredAddress.address,
            postcode: registeredAddress.postcode,
            suburb: registeredAddress.suburb,
          },
          country: registeredAddress.country,
        },
      },
      onCompleted: (data) => {
        setCompanyDetails(data.updateCompany);
        setPage(0);
        setSubmitLoading(false);
      },
      onError: (error) => {
        console.log(error);
        // TODO ADD ERROR MESSAGE
        setError("Error updating registered address. Please try again. ");
        setSubmitLoading(false);
      },
    });
  };

  const onTradingAddressUpdate = () => {
    // TODO: update company details with new trading addresses
    setError("");
    setSubmitLoading(true);
    updateCompany({
      variables: {
        companyId: loadSelectedCompanyId(),
        input: {
          tradingAddresses: tradingAddresses.map((address) => {
            return {
              address: address.address,
              postcode: address.postcode,
              state: address.state,
              suburb: address.suburb,
            };
          }),
        },
      },
      onCompleted: (data) => {
        setCompanyDetails(data.updateCompany);
        setPage(0);
        setSubmitLoading(false);
      },
      onError: (error) => {
        console.log(error);
        // TODO ADD ERROR MESSAGE
        setError("Error: unable to update trading address. Please try again later. ");
        setSubmitLoading(false);
      },
    });
  };

  const openNewTradingAddressPage = () => {
    setPage(4);
    setTradingAddressState({
      address: "",
      suburb: "",
      state: "",
      postcode: "",
    });
  };

  const openEditTradingAddressPage = (tradingAddress, i) => {
    setTradingAddressState({ ...tradingAddress, index: i });
    setPage(3);
  };

  const onEditTradingAddressSubmit = () => {
    setSubmitLoading(true);
    const newAddresses = tradingAddresses.map((address, i) => {
      if (tradingAddressState.index === i) return { ...tradingAddressState };
      else return address;
    });
    // TODO: update business details
    setTradingAddresses([...newAddresses]);
    setCompanyDetails({ ...companyDetails, tradingAddresses: [...newAddresses] });
    setPage(2);
  };

  const onAddTradingAddressSubmit = () => {
    const newAddresses = [...tradingAddresses];
    newAddresses.push(tradingAddressState);

    setTradingAddresses(newAddresses);
    setCompanyDetails({
      ...companyDetails,
      tradingAddresses: [...newAddresses],
    });
    setPage(2);
  };

  const handleAddressPickerChange = (value, containsCountry) => {
    if (containsCountry) {
      setRegisteredAddress({
        ...registeredAddress,
        address: value.address || "",
        suburb: value.suburb || "",
        state: value.state || "",
        postcode: value.postcode || "",
        country: value.country || "",
      });
    } else {
      setTradingAddressState({
        ...tradingAddressState,
        address: value.address || "",
        suburb: value.suburb || "",
        state: value.state || "",
        postcode: value.postcode || "",
      });
    }
  };

  const deleteTradingAddress = (index) => {
    const newAddresses = [...tradingAddresses];
    newAddresses.splice(index, 1);
    setTradingAddresses([...newAddresses]);
    setCompanyDetails({ ...companyDetails, tradingAddresses: [...newAddresses] });
  };

  const handleKeyUp = (e) => {
    const ENTER = 13;

    if (e.keyCode === ENTER) {
      if (page === 1) onRegisteredAddressUpdate();
      else if (page === 2) onTradingAddressUpdate();
      else if (page === 3) onEditTradingAddressSubmit();
      else if (page === 4) onAddTradingAddressSubmit();
    }
  };

  const pages = [
    <BusinessDetails
      key="BusinessDetails"
      registeredAddress={registeredAddress}
      openEditTradingAddresses={openEditTradingAddresses}
      openEditRegisteredAddress={openEditRegisteredAddress}
    />,
    <EditTradingAddress
      key="EditRegisteredAddress"
      handleAddressPickerChange={handleAddressPickerChange}
      state={registeredAddress}
      handleChange={handleRegisteredAddressChange}
      containsCountry
    />,

    <TradingAddress
      key="TradingAddress"
      state={{ tradingAddresses: tradingAddresses }}
      openNewTradingAddressPage={openNewTradingAddressPage}
      openEditTradingAddressPage={openEditTradingAddressPage}
      deleteAddress={deleteTradingAddress}
      errors={[]}
    />,

    <EditTradingAddress
      key="EditTradingAddress"
      handleAddressPickerChange={handleAddressPickerChange}
      state={tradingAddressState}
      handleChange={handleTradingAddressChange}
    />,

    <NewTradingAddress
      key="NewTradingAddress"
      handleAddressPickerChange={handleAddressPickerChange}
      state={tradingAddressState}
      handleChange={handleTradingAddressChange}
    />,
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ ".MuiPaper-root": { maxWidth: page <= 1 ? "800px" : "600px" } }}
      disableRestoreFocus={true}
      onKeyUp={handleKeyUp}
    >
      {error && <ErrorMsg errorMessage={error} />}

      {pages[page]}

      <DialogActions>
        {page === 0 && <MKButton onClick={handleClose}>Close</MKButton>}

        {page === 1 && <MKButton onClick={openBusinessDetails}>Back</MKButton>}
        {page === 1 && (
          <MKButton type="submit" onClick={onRegisteredAddressUpdate}>
            Submit
          </MKButton>
        )}

        {page === 2 && <MKButton onClick={openBusinessDetails}>Back</MKButton>}
        {page === 2 && (
          <MKButton type="submit" onClick={onTradingAddressUpdate}>
            Submit
          </MKButton>
        )}

        {page === 3 && <MKButton onClick={openEditTradingAddresses}>Back</MKButton>}
        {page === 3 && (
          <MKButton disabled={submitLoading} type="submit" onClick={onEditTradingAddressSubmit}>
            Submit
          </MKButton>
        )}

        {page === 4 && <MKButton onClick={openEditTradingAddresses}>Back</MKButton>}
        {page === 4 && (
          <MKButton disabled={submitLoading} type="submit" onClick={onAddTradingAddressSubmit}>
            Submit
          </MKButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
