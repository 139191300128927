/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";

import logo from "../../assets/images/Truesight-left-aligned-logo.png";
import { useEffect } from "react";

function BasicLayout({ children }) {
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = logo;
  }, [logo]);

  return (
    <>
      <MKBox position="absolute" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" />

      <MKBox width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <MKBox
          position="absolute"
          top="15%" // Center vertically
          left="50%" // Center horizontally
          width={{ xs: "80%", sm: "434px" }}
          sx={{
            transform: "translate(-50%, -50%)", // Adjust for perfect centering
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "100%",
              marginRight: "8px",
            }}
            src={logo}
            alt="logo"
          />
        </MKBox>

        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid pl={"0 !important"} item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
