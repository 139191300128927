import React, { useEffect, useState } from "react";
import MainContainer from "components/MainContainer/MainContainer";
import MKBox from "components/MKBox";
import { Tab, Tabs } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import UsersList from "./UsersList";
import RolesList from "./RolesList";
import { getGqlErrorMessage, loadSelectedCompanyId } from "../../common";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { usersState, rolesState, openState } from "../../atoms/company";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

import { gql, useQuery, useMutation } from "@apollo/client";

export const ON_LOAD_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      roles {
        id
        name
        description
        canUploadReceipts
        canUploadInvoices
        canAddUsers
        canEditUsers
        canAddRoles
        canEditRoles
        canAddVendors
        canViewHistory
        roleImage
      }
      users {
        id
        firstName
        lastName
        displayName
        email
        profileImageUrl
        role {
          id
          name
        }
      }
    }
  }
`;

function Main() {
  const [searchParams] = useSearchParams();

  const selectedCompanyId = loadSelectedCompanyId();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
  });

  const roles = useRecoilValue(rolesState);
  const setRoles = useSetRecoilState(rolesState);

  const users = useRecoilValue(usersState);
  const setUsers = useSetRecoilState(usersState);

  const [roleNumbers, setRoleNumbers] = useState(null);
  const [page, setPage] = useState(searchParams.get("roles") ? 2 : 1);
  const open = useRecoilValue(openState);

  useEffect(() => {
    if (!loading && data) {
      if (!users) setUsers(data.company.users);
      if (!roles) setRoles(data.company.roles);
      handleRoleNumbers(data.company.users);
    }
  }, [loading, data]);

  const handleRoleNumbers = (users) => {
    const roleNumbersArr = users.reduce((acc, user) => {
      acc[user?.role?.id] = (acc[user?.role?.id] || 0) + 1;
      return acc;
    }, {});
    setRoleNumbers(roleNumbersArr);
  };

  const handlePageChange = (_, newValue) => setPage(newValue);

  return (
    <MainContainer title={page === 1 ? "Users" : "Roles"}>
      <MKBox minHeight="75vh" width="100%" sx={{ margin: "auto", overflow: "hidden" }}>
        <MKBox>
          {page && (
            <MKBox
              sx={{
                boxShadow: " 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                transition:
                  "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                position: "relative",

                borderColor: "divider",
                background: "white",
              }}
            >
              <Tabs value={page} onChange={handlePageChange} sx={{ pt: 1 }}>
                <Tab sx={{ fontWeight: "400" }} value={1} label="Users" />
                <Tab sx={{ fontWeight: "400" }} value={2} label="Roles" />
              </Tabs>
            </MKBox>
          )}

          {page === 1 && (
            <UsersList handleRoleNumbers={handleRoleNumbers} loading={loading} roles={roles} />
          )}
          {page === 2 && <RolesList roleNumbers={roleNumbers} loading={loading} />}
        </MKBox>
      </MKBox>
    </MainContainer>
  );
}

export default Main;
