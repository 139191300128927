import {
  Autocomplete,
  Avatar,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import MKInput from "components/MKInput";
import React, { useEffect } from "react";
import MKBox from "../../../components/MKBox";
import BusinessIcon from "@mui/icons-material/Business";
import MKTypography from "../../../components/MKTypography";

const AutoComplete = ({ data, value, handleFieldChange, error, freeSolo }) => {
  const hasLogo = value && value.logo;

  return (
    <Autocomplete
      sx={{
        ".MuiOutlinedInput-root": {
          padding: " 1px !important",
          pl: "3px !important",
          fontSize: "1rem",
          height: "70px",
          borderRadius: "10px",
        },
        ".MuiAutocomplete-endAdornment": {
          pr: error ? 2 : 0,
        },
      }}
      options={data}
      value={value}
      disablePortal={false}
      renderOption={(props, option) => (
        <li {...props} key={option.key} style={{ display: "flex", alignItems: "center" }}>
          {option.logo ? (
            <img
              src={option.logo}
              alt={option.label}
              style={{ height: "50px", marginRight: "8px" }}
            />
          ) : (
            <Avatar sx={{ marginRight: "8px" }}>
              <BusinessIcon />
            </Avatar>
          )}
          {option.label} {option.role && `(${option.role})`}
        </li>
      )}
      renderInput={(params) => (
        <MKBox alignItems="center" width={"100%"} sx={{ minWidth: { sm: "300px" } }}>
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {hasLogo ? (
                    <img
                      src={value?.logo}
                      alt=""
                      style={{
                        marginLeft: "15px",
                        height: "50px",
                        marginRight: "15px",
                        verticalAlign: "middle",
                      }}
                    />
                  ) : (
                    <MKBox>
                      {value && (
                        <Avatar
                          sx={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            verticalAlign: "middle",
                          }}
                        >
                          <BusinessIcon />
                        </Avatar>
                      )}
                    </MKBox>
                  )}
                  <MKBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign={"left"}
                  >
                    <MKTypography
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: { xs: "110px", sm: "220px" }, // Adjust based on your layout
                        fontSize: { xs: "1rem", sm: "1.4rem" },
                      }}
                    >
                      {value?.label}
                    </MKTypography>
                    {value?.role && (
                      <MKTypography
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: { xs: "110px", sm: "220px" }, // Adjust based on your layout
                          fontSize: { xs: "0.6rem", sm: "0.8rem" },
                          opacity: 0.75,
                          height: "20px",
                        }}
                      >
                        ({value?.role})
                      </MKTypography>
                    )}
                  </MKBox>
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              value: "",
              readOnly: true,
            }}
            error={error}
            helperText={error && "Required field."}
            variant="outlined"
          />
        </MKBox>
      )}
      onChange={(e, value) => handleFieldChange(value)}
      isOptionEqualToValue={(option, value) => {
        if (value === option && value !== "") return true;
      }}
      freeSolo={freeSolo || false}
    />
  );
};

export default AutoComplete;
