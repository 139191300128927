import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { companiesState, companyDetailsState } from "../../../atoms/company";

import Slide from "@mui/material/Slide";
import AutoComplete from "components/Autocomplete";
import MKTypography from "components/MKTypography";
import DialogContentText from "@mui/material/DialogContentText";

const DeleteAccount = ({ open, handleClose, onSubmit }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyDetails = useRecoilValue(companyDetailsState);

  const handleOnKeyUp = (e) => {
    const ENTER = 13;

    if (e.keyCode === ENTER) {
      if (selectedCompany) onSubmit(selectedCompany);
    }
  };

  return (
    <Slide direction="down" in={open} timeout={500}>
      <MKBox>
        <Dialog open={open} onClose={handleClose} onKeyUp={handleOnKeyUp}>
          <DialogTitle sx={{ fontSize: "22px !important" }}>
            Delete account: {companyDetails?.name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this account?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MKButton onClick={handleClose}>No</MKButton>
            <MKButton onClick={onSubmit}>Yes</MKButton>
          </DialogActions>
        </Dialog>
      </MKBox>
    </Slide>
  );
};

export default DeleteAccount;
