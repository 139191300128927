import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import { Container } from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

const RegAddress = ({ state, handleChange, errors }) => {
  return (
    <>
      <DialogTitle sx={{ textAlign: "center" }}>Confirm registered address</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
          Your registered address is the one you used to officially register your business with a
          government body.{" "}
        </DialogContentText>
        <Container>
          <MKTypography variant="p">Country</MKTypography>
          <MKInput
            disabled
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.country}
          />

          <MKTypography variant="p">Street Address</MKTypography>
          <MKInput
            autoFocus
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.address}
            onChange={handleChange("address")}
            error={errors.includes("address")}
            helperText={errors.includes("address") && "Required field. "}
          />

          <MKTypography variant="p">Suburb</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.suburb}
            onChange={handleChange("suburb")}
            error={errors.includes("suburb")}
            helperText={errors.includes("suburb") && "Required field. "}
          />

          <MKTypography variant="p">State</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.state}
            onChange={handleChange("state")}
            error={errors.includes("state")}
            helperText={errors.includes("state") && "Required field. "}
          />

          <MKTypography variant="p">Postcode</MKTypography>
          <MKInput
            fullWidth
            size="small"
            value={state.postcode}
            onChange={handleChange("postcode")}
            error={errors.includes("postcode")}
            helperText={errors.includes("postcode") && "Required field. "}
          />
        </Container>
      </DialogContent>
    </>
  );
};

export default RegAddress;
