import React, { useState, useEffect } from "react";
import { Grid, Avatar, IconButton, Paper, Typography } from "@mui/material";
import MKTypography from "./MKTypography";
import MKBox from "./MKBox";
import MKInput from "./MKInput";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"; // for Manager
import ComputerIcon from "@mui/icons-material/Computer"; // for IT/Developer
import SupportAgentIcon from "@mui/icons-material/SupportAgent"; // for Support Staff
import EngineeringIcon from "@mui/icons-material/Engineering"; // for Engineer
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // for General Employee
import LocalShippingIcon from "@mui/icons-material/LocalShipping"; // for Logistics
import CleaningServicesIcon from "@mui/icons-material/CleaningServices"; // for Maintenance/Cleaning
import StorefrontIcon from "@mui/icons-material/Storefront"; // for Retail Staff

const iconMap = {
  Manager: BusinessCenterIcon,
  Developer: ComputerIcon,
  Support: SupportAgentIcon,
  Engineer: EngineeringIcon,
  Employee: AccountCircleIcon,
  Logistics: LocalShippingIcon,
  Maintenance: CleaningServicesIcon,
  Retail: StorefrontIcon,
};

const IconSelector = ({ selectedIcon, handleSelectedIcon }) => {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    // Populate the initial list of icons
    const allIcons = Object.keys(iconMap)
      .sort()
      .map((key) => ({ component: iconMap[key], name: key }));
    setIcons(allIcons);
  }, []);

  return (
    <MKBox sx={{ mb: 2, minWidth: { xs: "250px", sm: "500px" } }}>
      <MKTypography fontWeight="bold">Select an icon </MKTypography>
      <AvatarDisplay selectedIcon={selectedIcon} />

      <Grid container spacing={1} sx={{ mt: 1, height: "auto", pb: 2, overflow: "auto" }}>
        {icons.map(({ component: Icon, name }, index) => (
          <Grid
            item
            key={index}
            xs={2}
            sm={1}
            md={1}
            lg={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "auto", // Adjust height to auto
              maxWidth: "100% !important",
            }}
          >
            <Paper
              elevation={2}
              onClick={() => handleSelectedIcon(name, Icon)}
              sx={{
                width: 65, // Paper width
                height: 65, // Paper height
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: name === selectedIcon?.name ? "#f5f5f5" : "white",
                cursor: "pointer",
              }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: "#b0b3b5",
                }}
              >
                <Icon />
              </Avatar>
              {/*<Typography variant="caption">{name}</Typography>*/}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </MKBox>
  );
};
const AvatarDisplay = ({ selectedIcon }) => (
  <MKBox display="flex">
    <Avatar
      sx={{
        bgcolor: "secondary.main",
        width: 80,
        height: 80,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
        border: "2px solid white",
        mt: 1,
      }}
    >
      {selectedIcon?.icon}
    </Avatar>
  </MKBox>
);

export default IconSelector;
