import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { Container } from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";

const sizes = ["0-5", "5-19", "20-199", "200+"];
const Verify = ({ state, handleChange, errors }) => {
  return (
    <>
      <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>
        Help us verify your account faster
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
          Without this information we can&apos;t verify your account.
        </DialogContentText>
        <Container>
          <MKTypography variant="p">Business Category</MKTypography>
          <MKInput
            autoFocus
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.category}
            onChange={handleChange("category")}
            error={errors.includes("category")}
            helperText={errors.includes("category") && "Required field. "}
          />

          <MKTypography variant="p">Please describe your business in more detail</MKTypography>

          <FormControl variant="standard" sx={{ mb: 2, mt: "2px", width: "100%" }}>
            <MKInput
              multiline
              rows={3}
              fullWidth
              size="small"
              sx={{}}
              value={state.description}
              onChange={handleChange("description")}
              error={errors.includes("description") || errors.includes("descriptionLength")}
              helperText={
                (errors.includes("description") && "Required field. ") ||
                (errors.includes("descriptionLength") && "Must be at least 5 characters. ")
              }
            />
            <FormHelperText id="standard-weight-helper-text">
              The description must be at least 5 characters.{" "}
            </FormHelperText>
          </FormControl>
          <MKTypography variant="p">Size of your business</MKTypography>

          <FormControl fullWidth sx={{ mb: 2, mt: "2px" }} error={errors.includes("companySize")}>
            <Select
              value={state.companySize}
              onChange={handleChange("companySize")}
              sx={{ p: "0.75rem", pl: "0" }}
              input={<OutlinedInput endAdornment={<ArrowDropDownIcon />} />}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {sizes.map((size, index) => (
                <MenuItem key={index} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
            {errors.includes("companySize") && <FormHelperText>Required field. </FormHelperText>}
            <FormHelperText id="standard-weight-helper-text">
              The number of employees.
            </FormHelperText>
          </FormControl>
        </Container>
      </DialogContent>
    </>
  );
};

export default Verify;
