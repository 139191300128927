import React, { useState, useEffect } from "react";

import MainContainer from "components/MainContainer/MainContainer";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Container, Paper } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";

import Grid from "@mui/material/Grid";

import { gql, useQuery, useMutation } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  companyDetailsState,
  currenciesState,
  accountsState,
  showAccountsState,
  showCurrenciesState,
  roleState,
} from "../../atoms/company";
import Alert from "@mui/material/Alert";

import MKButton from "components/MKButton";
import IconButton from "@mui/material/IconButton";

import AddAccount from "../../modals/Settings/addAccount";
import AddCurrency from "../../modals/Settings/addCurrency";

import List from "./components/List";
import { getGqlErrorMessage, loadSelectedCompanyId, stringsToLabels } from "../../common";
import ErrorMsg from "components/ErrorMsg";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";

export const ON_LOAD_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      showAccounts
      showCurrencies
      currencies
      accounts
      xeroCompanyName
    }
  }
`;

export const UPDATE_COMPANY_QUERY = gql`
  mutation UpdateCompany($companyId: ID!, $input: CompanyInput!) {
    updateCompany(companyId: $companyId, input: $input) {
      id
      name
      showAccounts
      showCurrencies
      currencies
      accounts
    }
  }
`;
export const DELETE_XERO_CONNECTION_QUERY = gql`
  mutation DeleteXeroConnection($companyId: ID!) {
    deleteXeroConnection(companyId: $companyId)
  }
`;


const SuccessAlert = () => {
  return (
    <Alert
      severity="success"
      sx={{
        mt: 2,
        mx: { xs: 2, sm: 3 },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        ".MuiAlert-icon": { pt: "10px" },
      }}
    >
      Settings Saved
    </Alert>
  );
};

function Settings() {
  let navigate = useNavigate();
  const selectedCompanyId = loadSelectedCompanyId();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY_QUERY);
  const [deleteXeroConnection] = useMutation(DELETE_XERO_CONNECTION_QUERY);

  const companyDetails = useRecoilValue(companyDetailsState);

  const accounts = useRecoilValue(accountsState);
  const setAccounts = useSetRecoilState(accountsState);

  const currencies = useRecoilValue(currenciesState);
  const setCurrencies = useSetRecoilState(currenciesState);

  const autoCurrency = true

  const [openCurrencyAddModal, setOpenCurrencyAddModal] = useState(false);
  const [openAccountAddModal, setOpenAccountAddModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const showAccounts = useRecoilValue(showAccountsState);
  const setShowAccounts = useSetRecoilState(showAccountsState);

  const showCurrencies = useRecoilValue(showCurrenciesState);
  const setShowCurrencies = useSetRecoilState(showCurrenciesState);

  const [successfulSave, setSuccessfulSave] = useState(false);
  const [unsuccessfulSave, setUnsuccessfulSave] = useState(false);
  const [saveTimeoutId, setSaveTimeoutId] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [showAccountsLoading, setShowAccountsLoading] = useState(false);
  const [showCurrenciesLoading, setShowCurrenciesLoading] = useState(false);

  const role = useRecoilValue(roleState);

  useEffect(() => {
    if (!role?.canEditAppSettings) navigate("/");
  }, [navigate]);

  useEffect(() => {
    if (!loading) {
      if (!currencies) getCurrencies();
      if (!accounts) getAccounts();
    }
  }, [loading]);

  const onSuccessfulSave = () => {
    setSuccessfulSave(true);

    // Clear the previous timeout if it exists
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }

    // Set a new timeout and store its ID
    const timeoutId = setTimeout(() => setSuccessfulSave(false), 4000);
    setSaveTimeoutId(timeoutId);
  };
  const onUnsuccessfulSave = () => {
    setUnsuccessfulSave(true);
    if (saveTimeoutId) {
      clearTimeout(saveTimeoutId);
    }
    const timeoutId = setTimeout(() => setUnsuccessfulSave(false), 4000);
    setSaveTimeoutId(timeoutId);
  };

  const handleSelectedItemChange = (item) => {
    setSelectedItem(item);
  };

  const handleOpenCurrencyAddModal = () => {
    setOpenCurrencyAddModal(true);
  };

  const handleCloseCurrencyAddModal = () => {
    setOpenCurrencyAddModal(false);
  };

  const handleOpenAccountAddModal = () => {
    setOpenAccountAddModal(true);
  };

  const handleCloseAccountAddModal = () => {
    setOpenAccountAddModal(false);
  };

  const getConnectXeroUrl = () => {
    return (
      `https://us-central1-reconcile-app.cloudfunctions.net/xeroConsentUrl?companyId=` +
      selectedCompanyId
    );
  };
  const disconnectXero = () => {
    deleteXeroConnection({
        variables: {
          companyId: selectedCompanyId,
        },
        onCompleted: (data) => {
          onSuccessfulSave();
          location.reload()
        },
        onError: (error) => {
          console.error("Unable to update settings: ", getGqlErrorMessage(error));
        },
      });
  };
  const getXeroCurrencies = async() => {
    return (
      fetch(
        `https://us-central1-reconcile-app.cloudfunctions.net/xeroCurrencies?companyId=` +
        selectedCompanyId
      )
        .then(res => res.json())
    );
  };

  const onAddAccountSubmit = (newAccount) => {
    const newAccounts = accounts.concat([{ label: newAccount.name, key: accounts.length }]);
    updateCompanySettings(showAccounts, showCurrencies, newAccounts, [...currencies], (error) => {
      onUnsuccessfulSave();
    });
    handleCloseAccountAddModal();
  };

  const onEditAccountSubmit = (newAccount) => {
    const newAccounts = accounts.map((account) => {
      if (selectedItem.key === account.key) {
        return { key: account.key, label: newAccount.name };
      } else {
        return account;
      }
    });
    updateCompanySettings(showAccounts, showCurrencies, newAccounts, [...currencies], (error) => {
      onUnsuccessfulSave();
    });
  };

  const onDeleteAccountSubmit = () => {
    const newAccounts = accounts.filter((account) => account.key !== selectedItem.key);
    updateCompanySettings(showAccounts, showCurrencies, newAccounts, [...currencies], (error) => {
      onUnsuccessfulSave();
    });
  };

  const onAddCurrencySubmit = (newCurrency) => {
    const label = newCurrency.name.toUpperCase();
    const newCurrencies = currencies.concat([{ label: label, key: label }]);
    updateCompanySettings(showAccounts, showCurrencies, [...accounts], newCurrencies, (error) => {
      onUnsuccessfulSave();
    });
    handleCloseCurrencyAddModal();
  };

  const onEditCurrencySubmit = (newCurrency) => {
    const label = newCurrency.name.toUpperCase();
    const newCurrencies = currencies.map((currency) => {
      if (selectedItem.key === currency.key) {
        return { label: label, key: label };
      } else {
        return currency;
      }
    });
    updateCompanySettings(showAccounts, showCurrencies, [...accounts], newCurrencies, (error) => {
      onUnsuccessfulSave();
    });
  };

  const onDeleteCurrencySubmit = () => {
    const newCurrencies = currencies.filter((currency) => currency.key !== selectedItem.key);
    updateCompanySettings(showAccounts, showCurrencies, [...accounts], newCurrencies, (error) => {
      onUnsuccessfulSave();
    });
  };

  function updateCompanySettings(
    isShowAccounts,
    isShowCurrencies,
    newAccounts,
    newCurrencies,
    onError
  ) {
    // sort currencies by name
    if (newCurrencies && newCurrencies.length > 1) {
      newCurrencies?.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    }
    setSubmitLoading(true);
    updateCompany({
      variables: {
        companyId: selectedCompanyId,
        input: {
          showAccounts: isShowAccounts,
          showCurrencies: isShowCurrencies,
          accounts: newAccounts?.map((accountName) => accountName.label) || [],
          currencies: newCurrencies?.map((currency) => currency.label) || [],
        },
      },
      onCompleted: (data) => {
        setShowAccounts(data.updateCompany.showAccounts || false);
        setShowCurrencies(data.updateCompany.showCurrencies || false);
        setAccounts(stringsToLabels(data.updateCompany.accounts));
        setCurrencies(stringsToLabels(data.updateCompany.currencies));
        onSuccessfulSave();
        setSubmitLoading(false);
        setShowAccountsLoading(false);
        setShowCurrenciesLoading(false);
      },
      onError: (error) => {
        console.error("Unable to update settings: ", getGqlErrorMessage(error));
        onError(error);
        setSubmitLoading(false);
        setShowAccountsLoading(false);
        setShowCurrenciesLoading(false);
      },
    });
  }

  const getAccounts = () => {
    setAccounts(stringsToLabels(data?.company?.accounts));
    setShowAccounts(data?.company?.showAccounts || false);
  };

  const getCurrencies = () => {
    setCurrencies(stringsToLabels(data?.company?.currencies));
    setShowCurrencies(data?.company?.showCurrencies || false);
  };

  const handleShowAccountsChange = async (e) => {
    setShowAccountsLoading(true);
    updateCompanySettings(
      e.target.checked,
      showCurrencies,
      [...accounts],
      [...currencies],
      (error) => {
        onUnsuccessfulSave();
      }
    );
  };

  const handleShowCurrenciesChange = (e) => {
    setShowCurrenciesLoading(true);

    updateCompanySettings(
      showAccounts,
      e.target.checked,
      [...accounts],
      [...currencies],
      (error) => {
        onUnsuccessfulSave();
      }
    );
  };

  let expenseLabelHoverText = "Simple labels your staff can add to receipts to help your accounting team reconcile"

  return (
    <MainContainer title="Settings">
      {error && (
        <ErrorMsg
          errorMsg={
            " An unknown error has occurred. Please contact your administrator for assistance."
          }
        />
      )}

      {successfulSave && <SuccessAlert />}

      {unsuccessfulSave && <ErrorMsg errorMsg={"Error saving settings, please try again. "} />}
      <MKBox sx={{ mb: { xs: 1, sm: 3 }, pr: "16px", pl: { xs: "16px", sm: "24px !important" } }}>
        <Container
          sx={{
            mt: { xs: 2, sm: successfulSave ? 2 : 3 },
            p: 2,
            background: "#fff",
            boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            maxWidth: "700px !important",
            ml: { xs: "auto", sm: "0 !important" },
          }}
        >
          <MKBox sx={{ p: 1, width: { xs: "100%", sm: "75%" } }}>
            {loading && <Loading size={40} />}

            {!loading && !error && (
              <MKBox sx={{ p: 0, pl: "0 !important" }}>
                {companyDetails?.name && (
                  <MKTypography fontSize={"105%"} fontWeight={"bold"}  sx={{ mb: 2 }}>
                    {companyDetails.name}
                  </MKTypography>
                )}
                <MKTypography fontSize={"90%"} fontWeight={"medium"} sx={{ mb: 1.5 }}>
                  Upload Form Options
                </MKTypography>

                <FormGroup sx={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={showAccountsLoading}
                        data-testid="accountsSwitch"
                        checked={showAccounts}
                        onChange={handleShowAccountsChange}
                      />
                    }
                    label="Show Expense Labels On Forms"
                    title={expenseLabelHoverText}
                  />
                </FormGroup>

                <MKBox display="flex">
                  <MKTypography title={expenseLabelHoverText} sx={{ mt: "6px", mr: "3px", fontSize: "17px" }}>
                    Expense Labels
                  </MKTypography>
                  <IconButton data-testid="add-account" onClick={handleOpenAccountAddModal}>
                    <AddIcon />
                  </IconButton>
                </MKBox>
                {accounts && (
                  <List
                    account
                    selectedItem={selectedItem}
                    handleSelectedItemChange={handleSelectedItemChange}
                    onDeleteSubmit={onDeleteAccountSubmit}
                    items={accounts}
                    onEditAccountSubmit={onEditAccountSubmit}
                  />
                )}

                <MKTypography fontSize={"90%"} fontWeight={"medium"} sx={{ mt: 4, mb: 2 }}>
                  Multi-currency Details
                </MKTypography>

                {currencies && !autoCurrency && (
                  <FormGroup sx={{ mt: 2, width: "210px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={showCurrenciesLoading}
                          data-testid="currenciesSwitch"
                          checked={showCurrencies}
                          onChange={handleShowCurrenciesChange}
                        />
                      }
                      label="Show Multi-currency"
                    />
                  </FormGroup>
                  )}
                  {currencies && !autoCurrency && (
                  <MKBox display="flex">
                    <MKTypography sx={{ mt: "6px", mr: "3px", fontSize: "17px" }}>
                      Currencies
                    </MKTypography>
                    <IconButton data-testid="add-currency" onClick={handleOpenCurrencyAddModal}>
                      <AddIcon />
                    </IconButton>
                  </MKBox>
                )}
                {currencies && !autoCurrency && (
                  <List
                    selectedItem={selectedItem}
                    handleSelectedItemChange={handleSelectedItemChange}
                    onDeleteSubmit={onDeleteCurrencySubmit}
                    items={currencies}
                    onEditCurrencySubmit={onEditCurrencySubmit}
                    loading={submitLoading}
                  />
                )}
                {currencies && autoCurrency && currencies.map(
                  currency => (
                    <MKBox fontSize={"85%"} sx={{ mb: 0.4 }} key={currency.key}>
                      <MKTypography variant="p">
                        {currency.key}
                      </MKTypography>
                    </MKBox>
                  )
                )}
                {currencies && autoCurrency && currencies.length === 0 && (
                    <MKBox sx={{ mb: 2 }}>
                      <MKTypography fontSize={"85%"} variant="p">
                        Please Connect to Xero to enable multi-currency
                      </MKTypography>
                    </MKBox>
                )}

                <MKTypography fontSize={"90%"} fontWeight={"medium"} sx={{ mt: 4, mb: 1.6 }}>
                  Xero Details
                </MKTypography>
                <MKBox sx={{ mb: 2 }}>
                  <MKTypography fontSize={"90%"} variant="p">{data?.company?.xeroCompanyName} </MKTypography>
                  <MKButton color={"primary"} sx={{ p: 0 }}>
                    <a
                      style={{
                        height: "40px",
                        width: "100%",
                        borderRadius: "5px",
                        minWidth: "140px",
                        color: "white",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "10px 15px",
                      }}
                      rel="noreferrer"
                      href={(!data?.company?.xeroCompanyName) ? getConnectXeroUrl() : "#"}
                      onClick={(!data?.company?.xeroCompanyName) ? "" : disconnectXero}
                    >

                      {(!data?.company?.xeroCompanyName) ? "Connect to Xero" : "Disconnect from Xero"}
                    </a>
                  </MKButton>
                </MKBox>
              </MKBox>
            )}
          </MKBox>
        </Container>
      </MKBox>
      <AddAccount
        open={openAccountAddModal}
        handleClose={handleCloseAccountAddModal}
        onSubmit={onAddAccountSubmit}
        loading={submitLoading}
      />

      <AddCurrency
        open={openCurrencyAddModal}
        handleClose={handleCloseCurrencyAddModal}
        onSubmit={onAddCurrencySubmit}
        loading={submitLoading}
      />
    </MainContainer>
  );
}

export default Settings;
