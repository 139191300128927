import { Alert } from "@mui/material";

const ErrorMsg = ({ errorMsg }) => {
  return (
    <Alert
      severity="error"
      sx={{
        // mb: 1,
        // mt: 1,
        // mx: { xs: 2, sm: 1 },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        ".MuiAlert-icon": { pt: "10px" },
      }}
    >
      {errorMsg}
    </Alert>
  );
};
export default ErrorMsg;
