import { atom } from "recoil";
import dayjs from "dayjs";

export const currenciesState = atom({
  key: "Currencies",
  default: null,
});

export const accountsState = atom({
  key: "Accounts",
  default: null,
});

export const vendorNamesState = atom({
  key: "VendorNames",
  default: null,
});

export const companyDetailsState = atom({
  key: "CompanyDetails",
  default: null,
});

export const companiesState = atom({
  key: "Companies",
  default: [],
});

export const rolesState = atom({
  key: "Roles",
  default: null,
});

export const usersState = atom({
  key: "Users",
  default: null,
});

export const showAccountsState = atom({
  key: "ShowAccounts",
  default: false,
});

export const showCurrenciesState = atom({
  key: "ShowCurrencies",
  default: false,
});

export const roleState = atom({
  key: "Role",
  default: null,
});

export const openState = atom({
  key: "Open",
  default: null,
});

export const invoicesReceiptsState = atom({
  key: "InvoicesReceipts",
  default: [],
});

export const uploadState = atom({
  key: "UploadState",
  default: {
    invoice: true,
    receipt: false,
    datePurchased: dayjs(),
    amount: "",
    purchaseDescription: "",
    dueDate: dayjs(),
    file: null,
    account: { key: "", label: "" },
    currency: { key: "", label: "" },
    vendorName: { key: "", label: "" },
    errors: {},
  },
});
