import React, { useState, useCallback, useRef, useEffect } from "react";

import { Dialog, DialogActions, Alert, TextField } from "@mui/material";

import MKButton from "components/MKButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { usersState, rolesState } from "../../atoms/company";
import AutoComplete from "components/Autocomplete";
import "react-image-crop/dist/ReactCrop.css";
import ImageCropper from "../../components/ImageCropper";

const AddUserModal = ({ open, handleClose, onSubmit, errorMessage, loading }) => {
  const roles = useRecoilValue(rolesState);

  const [user, setUser] = useState(null);

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    roleId: false,
  });

  const [autoCompleteRoles, setAutoCompleteRoles] = useState(null);

  const [image, setImage] = useState("");

  useEffect(() => {
    const activeRoles = roles.map((role) => {
      return { key: role.id, label: role.name };
    });
    setAutoCompleteRoles(activeRoles);
    setUser({ firstName: "", lastName: "", email: "", roleId: activeRoles[0] });
    setError({
      firstName: false,
      lastName: false,
      email: false,
      roleId: false,
    });
    setImage("");
  }, [open]);
  const handleChange = (input) => (e) => {
    setUser({ ...user, [input]: e.target.value });
  };

  const handleRoleChange = (value) => {
    setUser({ ...user, roleId: value });
  };

  const handleSubmit = () => {
    const errors = {
      firstName: !user.firstName,
      lastName: !user.lastName,
      email: !user.email,
      roleId: !user?.roleId?.key,
    };
    setError(errors);

    if (!user.firstName || !user.lastName || !user.email || !user?.roleId?.key) {
      return;
    }
    if (image) {
      user.profileImage = image;
    }
    onSubmit(user);
  };

  const handleImageSave = (dataURL) => {
    setImage(dataURL);
  };

  return (
    <Dialog disableRestoreFocus={true} open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>Add User</DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Container>
          {user && (
            <MKBox>
              {errorMessage && (
                <Alert severity="error" sx={{ mb: 2, ".MuiAlert-icon": { pt: "10px" } }}>
                  {errorMessage}
                </Alert>
              )}
              <ImageCropper margin="auto" handleImageSave={handleImageSave} />

              <MKTypography variant="p">First name</MKTypography>
              <TextField
                data-testid="firstName"
                autoFocus
                fullWidth
                size="small"
                sx={{ mb: 2, mt: "2px" }}
                value={user.firstName}
                onChange={handleChange("firstName")}
                error={error.firstName}
                helperText={error.firstName && "Required field. "}
              />

              <MKTypography variant="p">Last name</MKTypography>
              <TextField
                data-testid="lastName"
                fullWidth
                size="small"
                sx={{ mb: 2, mt: "2px" }}
                value={user.lastName}
                onChange={handleChange("lastName")}
                error={error.lastName}
                helperText={error.lastName && "Required field. "}
              />

              <MKTypography variant="p">Email</MKTypography>
              <TextField
                data-testid="email"
                fullWidth
                size="small"
                sx={{ mb: 2, mt: "2px" }}
                value={user.email}
                onChange={handleChange("email")}
                error={error.email}
                helperText={error.email && "Required field. "}
              />
              <MKTypography variant="p">Role</MKTypography>

              {roles && (
                <AutoComplete
                  data={autoCompleteRoles}
                  value={user.roleId}
                  handleFieldChange={handleRoleChange}
                  error={error.roleId}
                />
              )}
            </MKBox>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <MKButton onClick={handleClose}>Cancel</MKButton>
        <MKButton onClick={handleSubmit} disabled={loading}>
          Submit
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
