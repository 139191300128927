import React, { useEffect, useMemo, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import { Container } from "@mui/material";

export const FilePreviewer = ({ files, selectedIndex, onNavigate }) => {
  const file = files[selectedIndex];

  const filePreview = useMemo(() => {
    const fileType = file.type;
    const fileURL = URL.createObjectURL(file);

    if (fileType.startsWith("image/")) {
      return (
        <img
          src={fileURL}
          alt={file.name}
          style={{
            height: "500px",
            width: "85%",
            objectFit: "contain",
          }}
        />
      );
    } else if (fileType.startsWith("audio/")) {
      return <audio controls src={fileURL} />;
    } else if (fileType.startsWith("video/")) {
      return <video controls src={fileURL} style={{ width: "100%", height: "auto" }} />;
    } else if (fileType === "application/pdf") {
      const pdfUrlWithParams = `${fileURL}#pagemode=none`;
      return <iframe src={pdfUrlWithParams} style={{ width: "100%", height: "100%" }} />;
    }
  }, [file]);
  return (
    <Container
      sx={{
        mt: 3,
        height: "100%",
        background: "#fff",
        boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        borderRadius: "5px",
        // padding: "20px", // Add padding
        p: 0,
        pr: "2px !important",
        pl: "2px !important",
        display: { md: "none", lg: "flex" },
        flexDirection: "column",

        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <MKBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          width: "100%",
          height: "90%",
        }}
      >
        <IconButton
          onClick={() => onNavigate(-1)}
          disabled={selectedIndex <= 0}
          sx={{ color: "primary.main", mr: "4px" }} // Optional: Change the color
        >
          <ArrowBackIcon fontSize={"large"} />
        </IconButton>
        {filePreview}
        <IconButton
          onClick={() => onNavigate(1)}
          disabled={selectedIndex >= files.length - 1}
          sx={{ color: "primary.main" }} // Optional: Change the color
        >
          <ArrowForwardIcon fontSize={"large"} />
        </IconButton>
      </MKBox>{" "}
      <MKTypography
        variant="h5" // Adjust the typography variant
        sx={{
          textAlign: "center",
          color: "text.primary",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          mb: 1,
        }}
      >
        {file?.name}
      </MKTypography>
    </Container>
  );
};
