import React from "react";

import { FileUploader } from "react-drag-drop-files";
import CloseIcon from "@mui/icons-material/Close";
import MKIconButton from "../../components/MKIconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Paper } from "@mui/material";
import MKButton from "../../components/MKButton";

const FILE_TYPES = [
  "7Z",
  "ABA",
  "BMP",
  "CSV",
  "DOC",
  "DOCX",
  "EML",
  "GIF",
  "HEIC",
  "JPEG",
  "JPG",
  "KEYNOTE",
  "MSG",
  "NUMBERS",
  "ODF",
  "ODS",
  "ODT",
  "OFX",
  "PAGES",
  "PDF",
  "PNG",
  "PPT",
  "PPTX",
  "QIF",
  "RAR",
  "RTF",
  "TIF",
  "TIFF",
  "TXT",
  "XLS",
  "XLSX",
  "XML",
  "ZIP",
];

const DocumentUploader = ({
  files,
  handleFileClick,
  handleDeleteFile,
  handleFileChange,
  setErrorMessage,
}) => {
  return (
    <>
      <MKTypography fontSize="16px" fontWeight="medium" mb={"6px"}>
        File Upload
      </MKTypography>
      <MKBox>
        <FileUploader
          multiple={true}
          handleChange={handleFileChange}
          name="file"
          types={FILE_TYPES}
          dropMessageStyle={{
            width: { xs: "auto", sm: "400px" },
            top: 0,
            left: "0px",
            height: "52px",
            background: "rgb(172 172 172)",
            opacity: 1,
          }}
          maxSize={10}
          onSizeError={() => {
            setErrorMessage(
              "Sorry, the file you are trying to upload is too large. Please ensure the file size does not exceed 10 MB and try again."
            );
            setTimeout(() => setErrorMessage(""), 4000);
          }}
        >
          <Paper
            sx={{
              width: { xs: "auto !important", sm: "400px" },
              height: "100%",
              borderRadius: "10px",
              p: 1,
              "&:hover": { cursor: "pointer", opacity: "0.85" },
            }}
          >
            <MKBox display={"flex"} alignItems="center">
              <FileUploadIcon sx={{ ml: 1, mt: { xs: "0", sm: 0 } }} fontSize="large" />
              <MKTypography fontSize="16px" ml={2}>
                Drag and drop or browse files here.
              </MKTypography>
            </MKBox>
          </Paper>
        </FileUploader>
        {files.length !== 0 && (
          <MKBox mt={files.length === 0 ? 0 : 1}>
            <MKTypography fontSize="16px" fontWeight="medium" mb="6px">
              Files:
            </MKTypography>
            <MKBox>
              {files?.map((file, i) => (
                <MKBox
                  key={i}
                  display="flex"
                  alignItems="center"
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 2,
                    pr: 1,
                    marginBottom: "8px",
                    maxWidth: "100%",
                  }}
                >
                  <MKBox
                    sx={{
                      ml: 2,
                      flexGrow: 1, // Allow this box to grow as needed
                      flexShrink: 1, // Allow this box to shrink as needed
                      minWidth: 0, // Important for text overflow in flex children
                    }}
                  >
                    <MKTypography
                      fontSize="15px"
                      noWrap // Use MUI noWrap for ellipsis
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.name}
                    </MKTypography>
                  </MKBox>
                  <MKBox display="flex">
                    <MKButton
                      sx={{
                        display: { md: "block", lg: "none" },
                        mt: "2px",
                        mx: "4px",
                      }}
                      variant="contained"
                      onClick={() => handleFileClick(i)}
                    >
                      Preview
                    </MKButton>
                    <MKIconButton
                      size="small"
                      sx={{ p: "6px", mx: "4px" }} // Add horizontal margin
                      onClick={() => handleDeleteFile(i)}
                    >
                      <CloseIcon color="primary" fontSize="medium" />
                    </MKIconButton>
                  </MKBox>
                </MKBox>
              ))}
            </MKBox>
          </MKBox>
        )}
      </MKBox>
    </>
  );
};
export default DocumentUploader;
