import React, { useEffect, useRef } from "react";

const LocationPicker = ({ containsCountry, handleAddressPickerChange }) => {
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!window.google) {
      console.error("Google Maps JavaScript API is not loaded");
      return;
    }
    const google = window.google;
    const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current, {
      types: ["address"],
    });

    const handlePlaceSelect = () => {
      console.log("Place change event triggered"); // Log when the place_changed event fires

      let addressObject = autocomplete.getPlace();
      let address = addressObject.address_components;

      if (addressObject) {
        let addressString = "";
        let state = "";
        let suburb = "";
        let postcode = "";
        let country = "";

        for (const component of address) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": {
              addressString = `${component.long_name} ${addressString}`;
              break;
            }

            case "route": {
              addressString += component.short_name;
              break;
            }

            case "postal_code": {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case "postal_code_suffix": {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case "locality":
              suburb = component.long_name;
              break;
            case "administrative_area_level_1": {
              state = component.short_name;
              break;
            }
            case "country":
              country = component.long_name;
              break;
          }
        }
        if (containsCountry) {
          handleAddressPickerChange(
            {
              address: addressString,
              state: state || "",
              suburb: suburb || "",
              postcode: postcode || "",
              country: country || "",
            },
            containsCountry
          );
        } else {
          handleAddressPickerChange({
            address: addressString,
            state: state || "",
            suburb: suburb || "",
            postcode: postcode || "",
            country: country || "",
          });
        }
      }
    };

    autocomplete.addListener("place_changed", handlePlaceSelect);
  }, []);
  return (
    <input
      style={{
        width: "100%",
        height: "45px",
        padding: "0.625rem",
        borderRadius: "5px",
        border: "1px solid #c3c0c0d6",
        marginBottom: "16px",
      }}
      id="autocomplete"
      className="input-field"
      type="text"
      ref={autocompleteRef}
    />
  );
};

export default LocationPicker;
