import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Grid,
  ListItemText,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MainContainer from "components/MainContainer/MainContainer";

import EditUserModal from "modals/Users/editUser";
import DeleteModal from "modals/deleteModal";
import NewPassword from "modals/Users/newPassword";
import { getGqlErrorMessage, loadSelectedCompanyId, loadUserId } from "../../common";
import { gql, useQuery, useMutation } from "@apollo/client";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { roleState, usersState } from "../../atoms/company";
import ImageIcon from "@mui/icons-material/Image";
import Loading from "../../components/Loading";
import ErrorMsg from "../../components/ErrorMsg";
import PersonIcon from "@mui/icons-material/Person";

export const ON_LOAD_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      roles {
        id
        name
      }
      users {
        id
        firstName
        lastName
        displayName
        email
        role {
          id
          name
        }
        createdBy {
          firstName
          lastName
        }
        createdAt
        profileImageUrl
      }
    }
  }
`;

export const UPDATE_USER_QUERY = gql`
  mutation UpdateUser($companyId: ID!, $userId: ID!, $input: UserInput!) {
    updateUser(companyId: $companyId, userId: $userId, input: $input) {
      id
      name
      users {
        id
        firstName
        lastName
        displayName
        email
        profileImageUrl
        role {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_USER_QUERY = gql`
  mutation DeleteUser($companyId: ID!, $userId: ID!) {
    deleteUser(companyId: $companyId, userId: $userId) {
      id
      name
      users {
        id
        firstName
        lastName
        displayName
        profileImageUrl
        email
        role {
          id
          name
        }
      }
    }
  }
`;

const User = () => {
  const selectedCompanyId = loadSelectedCompanyId();
  const userID = loadUserId();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
  });
  const [updateUser] = useMutation(UPDATE_USER_QUERY);
  const [deleteUser] = useMutation(DELETE_USER_QUERY);

  let navigate = useNavigate();

  const [allUsers, setAllUsers] = useState(null);
  const users = useRecoilValue(usersState);
  const setUsers = useSetRecoilState(usersState);
  const [user, setUser] = useState(null);

  const [roles, setRoles] = useState(null);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);

  const role = useRecoilValue(roleState);
  const handleEditModalOpen = () => {
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleDeleteModalOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (!loading) {
      getUser();
      getRoles();
    }
  }, [loading]);

  const getRoles = () => {
    const roleArr = data?.company?.roles || [];
    setRoles(
      roleArr.map((role) => {
        return { key: role.id, label: role.name };
      })
    );
  };
  const getUser = () => {
    const userId = window.location.href.split("/").filter(Boolean).pop();
    const user = data?.company?.users.find((user) => user.id === userId);
    setAllUsers(users || data?.company?.users);
    setUser(user);
  };

  const onEditSubmit = (modifiedUser) => {
    if (!role?.canEditUsers) return;

    setSubmitLoading(true);
    updateUser({
      variables: {
        companyId: selectedCompanyId,
        userId: user.id,
        input: {
          roleId: modifiedUser.roleId.key,
          firstName: modifiedUser.firstName,
          lastName: modifiedUser.lastName,
          email: modifiedUser.email,
          profileImage: modifiedUser.profileImage,
        },
      },
      onCompleted: (data) => {
        setUsers(data.updateUser.users);
        setUser(data.updateUser.users.find((o) => o.id === user.id));
        handleEditModalClose();
        setSubmitLoading(false);
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to update user: " + error);
        setErrorMessage("Error: Unable to update the user. Please try again later. ");
        setSubmitLoading(false);
      },
    });
  };

  const onDeleteSubmit = () => {
    setSubmitLoading(true);
    if (!role?.canEditUsers) return;

    // Check if the user is an admin and if they are the only admin
    const isAdmin = user.role.name === "Admin";
    const adminCount = allUsers.filter((u) => u.role.name === "Admin").length;

    if (isAdmin && adminCount <= 1) {
      console.error("Cannot delete the only admin user");
      setSubmitLoading(false);
      return;
    }

    deleteUser({
      variables: {
        companyId: selectedCompanyId,
        userId: user.id,
      },
      onCompleted: (data) => {
        setUsers(data.deleteUser.users);
        setSubmitLoading(false);
        navigate("/user-access");
      },
      onError: (error) => {
        console.error("Unable to remove the user: " + getGqlErrorMessage(error));
        setSubmitLoading(false);
      },
    });
  };

  return (
    <MainContainer title="Team Member">
      {error && (
        <ErrorMsg
          errorMsg={
            "An unknown error has occurred. Please contact your administrator for assistance."
          }
        />
      )}

      <MKButton
        variant={"gradient"}
        color={"white"}
        sx={{
          my: 2,
          mt: { xs: "12px", sm: 2 },
          ml: { xs: 2, sm: 3 },
          boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          ".MuiSvgIcon-root": { fontSize: "22px !important" },
        }}
        onClick={() => navigate("/user-access")}
      >
        <ArrowBackIcon sx={{ mr: "10px" }} />
        <MKTypography fontSize="14px">Back</MKTypography>
      </MKButton>
      <MKBox sx={{ pr: "16px", pl: { xs: "16px", sm: "24px !important" } }}>
        <MKBox
          sx={{
            mb: 2,
            background: "#fff",
            boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            maxWidth: "700px !important",
            ml: { xs: "auto", sm: "0 !important" },
            p: 4,
          }}
        >
          {loading && <Loading />}

          {user && (
            <MKBox sx={{ display: "flex", flexDirection: "column" }}>
              <MKBox sx={{ width: "120px" }}>
                {user?.profileImageUrl?.length !== 0 && (
                  <MKBox sx={{ width: "140px", height: "140px" }}>
                    <img
                      src={user.profileImageUrl}
                      alt="User Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%", // Circular shape
                      }}
                    />
                  </MKBox>
                )}

                {user?.profileImageUrl?.length === 0 && (
                  <Avatar sx={{ width: "140px", height: "140px" }}>
                    <PersonIcon sx={{ width: "80%", height: "80%" }} />
                  </Avatar>
                )}
              </MKBox>
              <MKTypography mt={1} variant="h5">
                {user?.firstName} {user?.lastName}
              </MKTypography>
              {role?.canEditUsers && (
                <MKBox
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    mt: 2,
                    width: { xs: "100%", sm: "280px" },

                    justifyContent: "space-between",
                  }}
                >
                  <MKButton
                    sx={{ pt: "11px", minWidth: "110px" }}
                    color="primary"
                    onClick={handleEditModalOpen}
                  >
                    Edit User
                  </MKButton>
                  {userID !== user?.id && (
                    <MKButton
                      sx={{ pt: "11px", mt: { xs: 1, sm: 0 } }}
                      color="error"
                      onClick={handleDeleteModalOpen}
                    >
                      Remove User
                    </MKButton>
                  )}
                </MKBox>
              )}
              <MKBox sx={{ mt: 6 }}>
                <MKTypography variant="h5">Team Member Information</MKTypography>
                <Grid
                  sx={{ maxWidth: "1000px" }}
                  mt={1}
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Email</Typography>
                    <Typography variant="body2" noWrap>
                      {user?.email}
                    </Typography>{" "}
                    {/* Text wrapping */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Role</Typography>
                    <Typography variant="body2" noWrap>
                      {user?.role?.name}
                    </Typography>
                  </Grid>

                  {user?.createdBy && (
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary="Added By"
                        secondary={user.createdBy.displayName || user.createdBy.email}
                      />
                    </Grid>
                  )}

                  {user.createdAt && (
                    <Grid item xs={12} sm={6}>
                      <ListItemText primary="Joined" secondary={user?.createdAt} />
                    </Grid>
                  )}
                </Grid>
              </MKBox>
              {roles && allUsers && (
                <EditUserModal
                  roles={roles}
                  userDetails={user}
                  open={openEditModal}
                  handleClose={handleEditModalClose}
                  onSubmit={onEditSubmit}
                  errorMessage={errorMessage}
                  loading={submitLoading}
                  disableRoleInput={
                    user &&
                    user.role &&
                    user.role.name === "Admin" &&
                    allUsers?.filter((u) => u.role?.name === "Admin").length === 1
                  }
                />
              )}
              <DeleteModal
                open={openDeleteModal}
                onClose={handleDeleteModalClose}
                handleDelete={onDeleteSubmit}
                message="Are you sure you want to delete this user?"
                loading={submitLoading}
              />
            </MKBox>
          )}
        </MKBox>
      </MKBox>
    </MainContainer>
  );
};

export default User;
