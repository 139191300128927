import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Grid, ListItemText } from "@mui/material";

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { companyDetailsState } from "../../../atoms/company";
import MKIconButton from "../../../components/MKIconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ImageCropper from "../../../components/ImageCropper";
import { gql, useQuery, useMutation } from "@apollo/client";
import { getGqlErrorMessage, loadSelectedCompanyId, stringsToLabels } from "../../../common";
import ErrorMsg from "../../../components/ErrorMsg";

const UPDATE_LOGO_QUERY = gql`
  mutation UpdateCompany($companyId: ID!, $input: CompanyInput!) {
    updateCompany(companyId: $companyId, input: $input) {
      logo
    }
  }
`;

const ListItem = ({ primary, secondary }) => {
  return (
    <Grid item xs={8} sx={{ minWidth: "320px" }}>
      <ListItemText
        primaryTypographyProps={{ fontSize: "15px" }}
        secondaryTypographyProps={{ fontSize: "13px" }}
        primary={primary}
        secondary={secondary}
      />
    </Grid>
  );
};
const BusinessDetails = ({ openEditRegisteredAddress, openEditTradingAddresses }) => {
  const [updateLogo] = useMutation(UPDATE_LOGO_QUERY);

  const companyDetails = useRecoilValue(companyDetailsState);
  const setCompanyDetails = useSetRecoilState(companyDetailsState);

  const [image, setImage] = useState(null);

  const [error, setError] = useState("");

  useEffect(() => {
    setImage(companyDetails?.logo || "");
  }, []);
  const handleImageSave = (dataURL) => {
    setImage(dataURL);
    const selectedCompanyId = loadSelectedCompanyId();
    setError("");

    updateLogo({
      variables: {
        companyId: selectedCompanyId,
        input: {
          logo: dataURL,
        },
      },
      onCompleted: (data) => {
        setCompanyDetails({ ...companyDetails, logo: dataURL });
      },
      onError: (error) => {
        console.error("Unable to update logo: ", getGqlErrorMessage(error));
        setError("Error: unable to update logo. Please try again later. ");
      },
    });
  };

  return (
    <>
      <DialogTitle margin={"auto"}>Business Details</DialogTitle>
      {error && <ErrorMsg errorMessage={error} />}

      <DialogContent>
        <Container>
          {image?.length >= 0 && (
            <MKBox width={"122px"} m={"auto"}>
              <ImageCropper image={image} handleImageSave={handleImageSave} />
            </MKBox>
          )}
          <MKTypography sx={{ borderBottom: "rgba(52, 71, 103, 0.2) 1px solid", mb: 2 }}>
            Business information
          </MKTypography>
          <MKAlert color="light">
            <ErrorOutlineIcon fontSize="large" sx={{ mr: 2 }} />
            <MKTypography variant="p" fontSize="14px">
              You cannot change these details online because your business is verified. If you need
              to change your business details, please{" "}
              <a
                href="https://www.truesight.au/contact"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                contact TrueSight
              </a>
              .
            </MKTypography>
          </MKAlert>
          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <ListItem primary="Registered business name" secondary={companyDetails?.name} />
              <ListItem primary="Category" secondary={companyDetails?.category} />
              <ListItem primary="Company Type" secondary={companyDetails?.type} />
              <ListItem
                primary="Australian Business Number (ABN)"
                secondary={companyDetails?.abn}
              />
              <ListItem
                primary="Please describe your business in more detail"
                secondary={companyDetails?.description}
              />
              <ListItem primary="Australian Company Number (ACN)" secondary={companyDetails?.acn} />
              <ListItem primary="Size of your business" secondary={companyDetails?.companySize} />
            </Grid>
          </MKBox>
          <MKBox display="flex" borderBottom="rgba(52, 71, 103, 0.2) 1px solid" mt={3} mb={2}>
            <MKTypography pb="3px">Registered address</MKTypography>
            <MKIconButton
              onClick={openEditRegisteredAddress}
              data-testid={"registeredAddress"}
              sx={{
                marginTop: "3px",
                marginLeft: "15px",
                padding: "0 !important",
                paddingBottom: "4px !important",
                width: "28px",
                height: "28px",
              }}
            >
              <BorderColorIcon color={"primary"} fontSize={"small"} />
            </MKIconButton>
          </MKBox>
          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              <ListItem primary="Country" secondary={companyDetails?.country} />
              <ListItem primary="State" secondary={companyDetails?.companyAddress?.state} />
              <ListItem primary="Address" secondary={companyDetails?.companyAddress?.address} />
              <ListItem primary="Postcode" secondary={companyDetails?.companyAddress?.postcode} />
              <ListItem primary="Suburb" secondary={companyDetails?.companyAddress?.suburb} />
            </Grid>
          </MKBox>

          <MKBox display="flex" borderBottom="rgba(52, 71, 103, 0.2) 1px solid" mt={3} mb={2}>
            <MKTypography pb="3px">Trading addresses</MKTypography>
            <MKIconButton
              data-testid={"tradingAddress"}
              onClick={openEditTradingAddresses}
              sx={{
                marginTop: "3px",
                marginLeft: "15px",
                padding: "0 !important",
                paddingBottom: "4px !important",
                width: "28px",
                height: "28px",
              }}
            >
              <BorderColorIcon color={"primary"} fontSize={"small"} />
            </MKIconButton>
          </MKBox>
          <MKBox sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={16}>
              {companyDetails?.tradingAddresses.length !== 0 &&
                companyDetails?.tradingAddresses.map((address, i) => (
                  <Grid key={i} item xs={8} sx={{ minWidth: "320px" }}>
                    <ListItem
                      key={i}
                      primary={`Address ${i + 1}`}
                      secondary={`${address.address}, ${address.suburb}, ${address.state}, ${address.postcode}`}
                    />
                  </Grid>
                ))}
            </Grid>
          </MKBox>
        </Container>
      </DialogContent>
    </>
  );
};

export default BusinessDetails;
