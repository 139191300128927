/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormControlLabel from "@mui/material/FormControlLabel";
import { Container, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKDatePicker from "components/DatePicker";

import React, { useState, useEffect, useReducer } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import MainContainer from "components/MainContainer/MainContainer";
import AutoComplete from "../../components/Autocomplete";
import Loading from "../../components/Loading";

import dayjs from "dayjs";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  currenciesState,
  accountsState,
  vendorNamesState,
  showAccountsState,
  showCurrenciesState,
  roleState,
  invoicesReceiptsState,
  uploadState,
} from "../../atoms/company";
import { storage } from "../../firebase";
import ErrorMsg from "components/ErrorMsg";
import { getGqlErrorMessage, loadSelectedCompanyId, loadUserId, stringsToLabels } from "common";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import FilePreviewDialog from "../../modals/Upload/filePreviewer";
import { FilePreviewer } from "./FilePreviewer";
import DocumentUploader from "./FileUploader";
import { CheckboxControl, FieldWrapper } from "./components/form";
import Grid from "@mui/material/Grid";
export const ON_LOAD_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      showAccounts
      showCurrencies
      currencies
      accounts
      vendors
    }
  }
`;

export const UPLOAD_INVOICE_QUERY = gql`
  mutation Mutation($companyId: ID!, $userId: ID!, $input: UploadInvoiceInput!) {
    uploadInvoice(companyId: $companyId, userId: $userId, input: $input) {
      amountInCents
      createdDate
      currency
      purchaseDate
      deptCode
      description
      dueDate
      paidFrom
      receiptOrInvoice
      submittedByName
      submittedByUID
      vendorName
      xeroBillId
      xeroBillAttachmentNames
      xeroSyncStatus
    }
  }
`;

export const UPLOAD_RECEIPT_QUERY = gql`
  mutation Mutation($companyId: ID!, $userId: ID!, $input: UploadReceiptInput!) {
    uploadReceipt(companyId: $companyId, userId: $userId, input: $input) {
      amountInCents
      createdDate
      currency
      purchaseDate
      deptCode
      description
      dueDate
      paidFrom
      receiptOrInvoice
      submittedByName
      submittedByUID
      vendorName
      xeroBillId
      xeroBillAttachmentNames
      xeroSyncStatus
    }
  }
`;

const initialState = {
  invoice: true,
  receipt: false,
  datePurchased: dayjs(),
  amount: "",
  purchaseDescription: "",
  dueDate: dayjs(),
  file: null,
  account: { key: "", label: "" },
  currency: { key: "", label: "" },
  vendorName: { key: "", label: "" },
  errors: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };
    case "TOGGLE_RECEIPT_INVOICE":
      return { ...state, invoice: !state.invoice, receipt: !state.receipt };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

const SuccessAlert = (invoice) => {
  return (
    <Alert
      severity="success"
      sx={{
        mt: 2,
        mx: { xs: 2, sm: 3 },
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        ".MuiAlert-icon": { pt: "10px" },
      }}
    >
      {invoice ? "Invoice" : "Receipt"} was successfully uploaded.
    </Alert>
  );
};

function Upload() {
  let navigate = useNavigate();
  const [uploadInvoice] = useMutation(UPLOAD_INVOICE_QUERY);
  const [uploadReceipt] = useMutation(UPLOAD_RECEIPT_QUERY);

  const selectedCompanyId = loadSelectedCompanyId();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
  });

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const globalUploadState = useRecoilValue(uploadState);
  const setGlobalUploadState = useSetRecoilState(uploadState);
  const [state, dispatch] = useReducer(reducer, globalUploadState);

  const {
    invoice,
    receipt,
    amount,
    purchaseDescription,
    datePurchased,
    dueDate,
    account,
    currency,
    vendorName,
    errors,
  } = state;

  const accounts = useRecoilValue(accountsState);
  const setAccounts = useSetRecoilState(accountsState);

  const currencies = useRecoilValue(currenciesState);
  const setCurrencies = useSetRecoilState(currenciesState);

  const vendorNames = useRecoilValue(vendorNamesState);
  const setVendorNames = useSetRecoilState(vendorNamesState);

  const showAccounts = useRecoilValue(showAccountsState);
  const setShowAccounts = useSetRecoilState(showAccountsState);

  const showCurrencies = useRecoilValue(showCurrenciesState);
  const setShowCurrencies = useSetRecoilState(showCurrenciesState);

  const invoicesReceipts = useRecoilValue(invoicesReceiptsState);
  const setInvoiceReceipts = useSetRecoilState(invoicesReceiptsState);

  const role = useRecoilValue(roleState);

  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    // Sync the local state with global state
    setGlobalUploadState(state);
  }, [state, setGlobalUploadState]);

  const handleFileClick = (index) => {
    setSelectedFileIndex(index);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleNavigateFiles = (direction) => {
    setSelectedFileIndex((prevIndex) => prevIndex + direction);
  };

  useEffect(() => {
    if (!role?.canUploadInvoices && !role?.canUploadReceipts) navigate("/user-access");
    if (!loading && !error && data) {
      if (!currencies) getCurrencies();
      if (!vendorNames) getVendorNames();
      if (!accounts) getAccounts();
    }

    handleFieldChange("dueDate", dayjs());
    handleFieldChange("datePurchased", dayjs());
  }, [loading]);

  useEffect(() => {
    const errors = {
      currency: false,
      amount: false,
      vendorName: false,
      description: false,
      dueDate: false,
      account: false,
      datePurchased: false,
    };
    dispatch({ type: "SET_ERRORS", errors });
  }, [invoice]);

  const handleFieldChange = (field, value) => {
    dispatch({ type: "SET_FIELD", field, value });
  };

  const handleCheckBoxChange = () => {
    dispatch({ type: "TOGGLE_RECEIPT_INVOICE" });
  };

  const setValidationErrors = (errors) => {
    dispatch({ type: "SET_ERRORS", errors });
  };

  const handleDeleteFile = (index) => {
    // If last file is deleted and user is previewing this file, move forward one file
    if (selectedFileIndex === files.length - 1 && selectedFileIndex !== 0)
      setSelectedFileIndex((prevState) => prevState - 1);
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileChange = (selectedFiles) => {
    const filesArray = Array.from(selectedFiles); // Convert FileList to Array
    setFiles([...files, ...filesArray]); // Set the files in state
  };

  const handleFileUpload = async () => {
    // Create an array of promises
    const uploadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        let cleanerName = file.name.replace(/[^a-zA-Z0-9_().-]+/g, "_").replace(/\s+/g, " ").trim()
        let newFilename = `${dayjs().format('YYYY-MM-DD_HH.mm')}_${cleanerName}`;
        const storageRef = ref(
          storage,
          `${selectedCompanyId}/uploads/${newFilename}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(`Upload of ${file.name} is ${progress}% done`);
          },
          (error) => {
            console.error(error);
            reject(error); // Reject the promise on error
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(`File ${file.name} available at: `, downloadURL);
              resolve(downloadURL); // Resolve the promise with the download URL
            });
          }
        );
      });
    });

    // Wait for all promises to resolve
    try {
      const imgURLs = await Promise.all(uploadPromises);
      console.log("All files uploaded:", imgURLs);
      return imgURLs; // Returns an array of download URLs
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const getAccounts = () => {
    setAccounts(stringsToLabels(data?.company?.accounts || []));
    setShowAccounts(data?.company?.showAccounts || false);
  };

  const getCurrencies = () => {
    setCurrencies(stringsToLabels(data?.company?.currencies || []));
    setShowCurrencies(data?.company?.showCurrencies || false);
  };

  const getVendorNames = () => {
    setVendorNames(stringsToLabels(data?.company?.vendors || []));
  };

  const validateFields = () => {
    const errors = {
      currency: showCurrencies ? !currency?.key : false,
      amount: !amount,
      vendorName: role.canAddVendors
        ? typeof vendorName === "string" || !vendorName
          ? !vendorName
          : vendorName?.key?.length === 0
        : vendorName?.key.length === 0,
      description: !purchaseDescription,
      dueDate: isNaN(dueDate["$D"]),
      paidFrom: receipt && (showAccounts ? account?.key.length === 0 : false),
      datePurchased: isNaN(datePurchased["$D"]),
    };
    setValidationErrors(errors);
    return Object.values(errors).some((val) => val); // returns false if there are any errors
  };

  const onSubmit = async (e) => {
    e.target.blur();
    setErrorMessage(null);
    if (uploadInProgress) {
      setErrorMessage("Error: file upload still in progress");
      return setTimeout(() => setErrorMessage(""), 4000);
    }
    if (!validateFields()) {
      setUploadInProgress(true);
      const imgUrls = await handleFileUpload();
      if (invoice) {
        if (role.canUploadInvoices) {
          await uploadInvoice({
            variables: {
              companyId: selectedCompanyId,
              userId: loadUserId(),
              input: {
                currency: currency.key,
                amountInCents: Math.round(parseFloat(amount) * 100),
                vendorName: vendorName.key || vendorName,
                description: purchaseDescription,
                dueDate: dueDate["$d"],
                fileUrlArr: imgUrls,
              },
            },
            onCompleted: (data) => {
              //TODO: handle success
              // add to vendor name list if option wasn't chosen
              if (!vendorName.key) {
                const newVendorNames = [...vendorNames, { key: vendorName, label: vendorName }];
                setVendorNames(newVendorNames);
              }
              setInvoiceReceipts([
                ...invoicesReceipts,
                { id: invoicesReceipts?.length || 0, ...data.uploadInvoice },
              ]);

              setUploadInProgress(false);
              setSuccessfulUpload(true);
              dispatch({ type: "RESET_STATE" }); // Reset the state after successful upload
              setFiles([]);

              setTimeout(() => setSuccessfulUpload(false), 4000);
            },
            onError: (error) => {
              console.error("Unable to upload invoice: ", getGqlErrorMessage(error));
              //TODO: handle error
              setUploadInProgress(false);
              setErrorMessage(
                "An error occurred while uploading the file. Please try again later."
              );
              setTimeout(() => setErrorMessage(""), 4000);
            },
          });
        }
      } else {
        if (role.canUploadReceipts) {
          await uploadReceipt({
            variables: {
              companyId: selectedCompanyId,
              userId: loadUserId(),
              input: {
                paidFrom: account.key,
                purchaseDate: datePurchased["$d"],
                currency: currency.key,
                amountInCents: Math.round(parseFloat(amount) * 100),
                vendorName: vendorName.key || vendorName,
                description: purchaseDescription,
                fileUrlArr: imgUrls,
              },
            },
            onCompleted: (data) => {
              //TODO: handle success
              if (!vendorName.key) {
                const newVendorNames = [...vendorName, { key: vendorName, label: vendorName }];
                setVendorNames(newVendorNames);
              }
              setInvoiceReceipts([
                ...invoicesReceipts,
                { id: invoicesReceipts?.length || 0, ...data.uploadReceipt },
              ]);
              setUploadInProgress(false);
              setSuccessfulUpload(true);
              dispatch({ type: "RESET_STATE" });
              setFiles([]);
              setTimeout(() => setSuccessfulUpload(false), 4000);
            },
            onError: (error) => {
              console.error("Unable to upload invoice: ", getGqlErrorMessage(error));
              //TODO: handle error
              setUploadInProgress(false);
              setErrorMessage(
                "An error occurred while uploading the file. Please try again later."
              );
              setTimeout(() => setErrorMessage(""), 4000);
            },
          });
        }
      }
    }
  };

  const handleReset = (e) => {
    e.target.blur();
    setFiles([]);
    dispatch({ type: "RESET_STATE" });
  };

  return (
    <MainContainer title="Receipt - Invoices Upload" role={role}>
      {successfulUpload && <SuccessAlert invoice={invoice} />}
      {errorMessage && <ErrorMsg errorMsg={errorMessage} />}

      {error && (
        <ErrorMsg
          errorMsg={
            "An unknown error has occurred. Please contact your administrator for assistance."
          }
        />
      )}

      <Grid container sx={{ overflow: "hidden", pb: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={files.length === 0 ? 12 : 4} sx={{}}>
          <MKBox
            sx={{
              pr: "16px",
              pl: { xs: "16px", sm: "24px !important" },
            }}
          >
            <Container
              sx={{
                mt: { xs: 2, sm: successfulUpload ? 2 : 3 },
                p: 2,
                maxWidth: "600px !important",
                minWidth: "300px",
                ml: { xs: "auto", sm: "0 !important" },
                background: "#fff",
                boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                borderRadius: "5px",
              }}
            >
              <MKBox sx={{ p: 1, pt: 0, width: { sm: "100%" } }}>
                {loading && <Loading size={40} />}

                {!loading && !error && (
                  <MKBox sx={{ pl: "0 !important" }}>
                    <MKBox sx={{ mb: 1 }}>
                      <MKBox display={"flex"} alignItems="center" justifyContent={"space-between"}>
                        <MKTypography fontSize="16px" fontWeight="medium">
                          Receipt / Invoice
                        </MKTypography>
                      </MKBox>

                      <MKBox
                        display="flex"
                        justifyContent={"space-between"}
                        width={{ xs: "200px", sm: "230px" }}
                      >
                        <CheckboxControl
                          sx={{ mr: 0 }}
                          label="Invoice"
                          checked={invoice}
                          onChange={handleCheckBoxChange}
                        />
                        <CheckboxControl
                          label="Receipt"
                          checked={receipt}
                          onChange={handleCheckBoxChange}
                        />
                      </MKBox>
                    </MKBox>

                    {receipt && [
                      <MKBox key="account">
                        {showAccounts && (
                          <FieldWrapper label="Expense Label">
                            {accounts && (
                              <AutoComplete
                                data={accounts}
                                value={account}
                                handleFieldChange={(value) => handleFieldChange("account", value)}
                                error={errors.paidFrom}
                              />
                            )}
                          </FieldWrapper>
                        )}
                      </MKBox>,
                      <FieldWrapper label="Date Purchased" key="datePurchased">
                        <MKDatePicker
                          size="small"
                          value={state.datePurchased}
                          onChange={(newValue) => handleFieldChange("datePurchased", newValue)}
                        />
                      </FieldWrapper>,
                    ]}

                    <MKBox>
                      {showCurrencies && (
                        <FieldWrapper label="Currency">
                          {currencies && (
                            <AutoComplete
                              data={currencies}
                              value={currency}
                              handleFieldChange={(value) => handleFieldChange("currency", value)}
                              error={errors.currency}
                            />
                          )}
                        </FieldWrapper>
                      )}
                    </MKBox>

                    <FieldWrapper label="Amount">
                      <TextField
                        data-testid="amount"
                        fullWidth
                        size="small"
                        type="number"
                        value={amount}
                        onChange={(e) => handleFieldChange("amount", e.target.value)}
                        error={errors.amount}
                        helperText={errors.amount && "Required field."}
                      />
                    </FieldWrapper>

                    <FieldWrapper label="Vendor Name">
                      {vendorNames && role && (
                        <AutoComplete
                          data={vendorNames}
                          value={vendorName}
                          handleFieldChange={(value) => handleFieldChange("vendorName", value)}
                          freeSolo={role.canAddVendors}
                          error={errors.vendorName}
                        />
                      )}
                    </FieldWrapper>

                    <FieldWrapper label="Description of Purchase">
                      <TextField
                        data-testid="description"
                        fullWidth
                        size="small"
                        value={purchaseDescription}
                        onChange={(e) => handleFieldChange("purchaseDescription", e.target.value)}
                        error={errors.description}
                        helperText={errors.description && "Required field."}
                      />
                    </FieldWrapper>

                    {invoice && (
                      <FieldWrapper label="Due Date">
                        <MKDatePicker
                          data-testid={"due-date"}
                          size="small"
                          value={state.datePurchased}
                          onChange={(newValue) => handleFieldChange("dueDate", newValue)}
                        />
                      </FieldWrapper>
                    )}
                    <DocumentUploader
                      files={files}
                      handleFileClick={handleFileClick}
                      handleDeleteFile={handleDeleteFile}
                      handleFileChange={handleFileChange}
                      setErrorMessage={setErrorMessage}
                    />

                    <MKBox display={"flex"} justifyContent={"space-between"} sx={{ mt: 2, mb: 2 }}>
                      <MKButton
                        sx={{ color: "white" }}
                        color="primary"
                        onClick={(e) => onSubmit(e)}
                        disabled={
                          (invoice && !role?.canUploadInvoices) ||
                          (receipt && !role?.canUploadReceipts)
                        }
                      >
                        {uploadInProgress ? (
                          <CircularProgress size={20} color={"white"} />
                        ) : (
                          "Submit"
                        )}
                      </MKButton>
                      <MKTypography
                        fontSize="15px"
                        mt={2}
                        sx={{ textDecoration: "underline", "&:hover": { cursor: "pointer" } }}
                        aria-label="clear"
                        onClick={handleReset}
                      >
                        Clear
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                )}
              </MKBox>
            </Container>
          </MKBox>
        </Grid>
        {files.length !== 0 && (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={7}
            sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
          >
            <MKBox pb={3} height={"100%"}>
              <FilePreviewer
                files={files}
                selectedIndex={selectedFileIndex}
                onNavigate={handleNavigateFiles}
              />
            </MKBox>
          </Grid>
        )}
      </Grid>
      {isDialogOpen && (
        <FilePreviewDialog
          files={files}
          selectedIndex={selectedFileIndex}
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onNavigate={handleNavigateFiles}
        />
      )}
    </MainContainer>
  );
}

export default Upload;
