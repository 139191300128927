import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MKButton from "components/MKButton";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogPaper: {
    width: "100%", // Adjust the width as needed,
    maxWidth: "450px",
  },
});
const DeleteModal = ({ value, open, onClose, handleDelete, message, loading }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableRestoreFocus={true}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>Delete Confirmation{value && `: ${value}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MKButton onClick={onClose}>Cancel</MKButton>
        <MKButton
          loading={loading}
          onClick={() => {
            onClose();
            handleDelete();
          }}
          disabled={loading}
        >
          Delete
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
