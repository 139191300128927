import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#fafafa', // Lighter background color
    padding: '0 1rem'
  },
  header1: {
    fontSize: '3rem',
    color: '#2C3E50',  // Dark Blue for headers
    width: '100px'
  },
  header2: {
    fontSize: '3rem',
    color: '#2C3E50',  // Dark Blue for headers
    marginBottom: '1rem',
    width: '348px'
  },
  text: {
    fontSize: '13px',
    color: '#7F8C8D',  // Gray for text
    marginBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '440px'
  },
  link: {
    textDecoration: 'none',
    color: '#3498DB',  // Light Blue for links
    fontSize: '13px',
    marginLeft: '0.2rem',
    overflowWrap: 'break-word'
  }
};

function NotFound() {
  return (
    <div style={styles.container}>
      <h2 style={styles.header1}>404</h2>
      <h2 style={styles.header2}>Page Not Found</h2>
      <div style={styles.text}>
        It looks like nothing was found at this location. Maybe try returning to the
        <Link to="/sign-in" style={styles.link}>
          home page
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
