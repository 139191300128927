import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import Loading from "components/Loading";

import Details from "./sections/details";
import RegAddress from "./sections/regAddress";
import Verify from "./sections/verify";
import TradingAddress from "./sections/tradingAddress";
import Summary from "./sections/summary";
import NewTradingAddress from "./sections/addTradingAddress";
import EditTradingAddress from "./sections/editTradingAddress";

import { gql, useMutation } from "@apollo/client";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { companiesState } from "../../../atoms/company";
import { getGqlErrorMessage, saveSelectedCompanyId } from "../../../common";
import { validateState } from "./validateState";
export const ADD_COMPANY_QUERY = gql`
  mutation AddCompany($input: CompanyInput!) {
    addCompany(input: $input) {
      id
      name
    }
  }
`;

const initialState = {
  name: "",
  country: "Australia",
  type: "",
  ABN: "",
  ACN: "",
  category: "",
  description: "",
  companySize: "",
  address: "",
  suburb: "",
  state: "",
  postcode: "",
  tradingAddresses: [],
  anzsicCode: "",
};

const AddCompanyModal = ({ open, handleClose }) => {
  const [addCompany] = useMutation(ADD_COMPANY_QUERY);

  const [page, setPage] = useState(0);

  const [state, setState] = useState(initialState);

  const [addressState, setAddressState] = useState({
    address: "",
    suburb: "",
    state: "",
    postcode: "",
  });

  const addressStateRef = useRef(addressState);

  let navigate = useNavigate();

  const companies = useRecoilValue(companiesState);
  const setCompanies = useSetRecoilState(companiesState);

  useEffect(() => {
    setState(initialState);
    setErrorMessages([]);
    if (open) setPage(0);
  }, [open]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async () => {
    setSubmitLoading(true);
    addCompany({
      variables: {
        input: {
          name: state.name,
          country: state.country,
          type: state.type,
          abn: state.ABN,
          acn: state.ACN,
          category: state.category,
          description: state.description,
          companySize: state.companySize,
          anzsicCode: state.anzsicCode,
          companyAddress: {
            address: state.address,
            suburb: state.suburb,
            state: state.state,
            postcode: state.postcode,
          },
          tradingAddresses: state.tradingAddresses,
        },
      },
      onCompleted: (data) => {
        //TODO: update UI if needed
        console.info("Added company: " + JSON.stringify(data.addCompany));

        setCompanies([
          ...companies,
          {
            key: companies.length,
            label: state.name,
          },
        ]);
        handleClose();
        setSubmitLoading(false);
        // Switch to new company
        saveSelectedCompanyId(data.addCompany.id);
        navigate("/settings");
        location.reload()
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to add the company: " + getGqlErrorMessage(error));
        setSubmitLoading(false);
        setErrorMessages(["Error: unable to add company. Please try again later. "]);
      },
    });
  };

  const handleChange = useCallback(
    (input) => (e) => {
      setState((prev) => ({ ...prev, [input]: e.target.value }));
    },
    []
  );

  const nextPage = useCallback(
    (fields) => {
      const errors = validateState(state, fields);
      if (errors.length === 0) {
        setPage((prevPage) => prevPage + 1);
        setErrorMessages([]);
      } else {
        setErrorMessages(errors);
      }
    },
    [state]
  );

  const previousPage = useCallback(() => {
    setPage((prevPage) => prevPage - 1);
  }, []);

  const openNewTradingAddressPage = () => {
    setPage(5);
    setAddressState({
      address: "",
      suburb: "",
      state: "",
      postcode: "",
    });
  };

  const closeNewTradingAddressPage = () => {
    setPage(3);
  };

  useEffect(() => {
    // using ref because addTradingAddress isn't capturing the latest state of addressState
    addressStateRef.current = addressState;
  }, [addressState]);

  const addTradingAddress = useCallback(() => {
    const currentAddressState = addressStateRef.current;
    setState((prevState) => ({
      ...prevState,
      tradingAddresses: [...prevState.tradingAddresses, { ...currentAddressState }],
    }));
    setPage(3);
  }, []);

  const deleteTradingAddress = (index) => {
    const newAddresses = [...state.tradingAddresses];
    newAddresses.splice(index, 1);
    setState({ ...state, tradingAddresses: newAddresses });
  };

  const openEditTradingAddressPage = (tradingAddress, i) => {
    setAddressState({ ...tradingAddress, index: i });
    setPage(6);
  };

  const closeEditTradingAddressPage = () => {
    setPage(3);
  };

  const editTradingAddress = () => {
    const currentAddressState = addressStateRef.current;
    setState((prevState) => ({
      ...prevState,
      tradingAddresses: prevState.tradingAddresses.map((address, i) =>
        i === currentAddressState.index ? { ...currentAddressState } : address
      ),
    }));
    setPage(3);
  };

  const handleTradingAddressChange = (input) => (e) => {
    setAddressState((prevState) => ({ ...prevState, [input]: e.target.value }));
  };

  const handleAddressPickerChange = (value) => {
    setAddressState({
      ...addressState,
      address: value.address || "",
      suburb: value.suburb || "",
      state: value.state || "",
      postcode: value.postcode || "",
    });
  };
  const prefillTradingAddress = () => {
    setState((prevState) => ({
      ...prevState,
      tradingAddresses: [
        {
          address: state.address,
          suburb: state.suburb,
          state: state.state,
          postcode: state.postcode,
        },
        ...prevState.tradingAddresses,
      ],
    }));
  };
  const handleAnzsicCodeChange = (value) => {
    setState({ ...state, anzsicCode: value?.split(":")[0] || "" });
  };

  const pageDetails = [
    { component: Details, back: false, next: true, onSubmit: null },
    { component: RegAddress, back: previousPage, next: true, onSubmit: null },
    { component: Verify, back: previousPage, next: true, onSubmit: null },
    { component: TradingAddress, back: previousPage, next: true, onSubmit: null },

    { component: Summary, back: previousPage, next: false, onSubmit: onSubmit },
    {
      component: NewTradingAddress,
      back: closeNewTradingAddressPage,
      next: false,
      onSubmit: addTradingAddress,
    },
    {
      component: EditTradingAddress,
      back: closeEditTradingAddressPage,
      next: false,
      onSubmit: editTradingAddress,
    },
  ];

  // const handleKeyUp = useCallback(
  //   (e) => {
  //     const ENTER = 13;
  //     if (false && e.keyCode === ENTER) {
  //       switch (page) {
  //         case 0:
  //           nextPage(["country", "name", "type", "ABN", "ACN"]);
  //           break;
  //         case 1:
  //           nextPage(["address", "suburb", "state", "postcode"]);
  //           break;
  //         case 2:
  //           nextPage(["category", "description", "companySize"]);
  //           break;
  //         case 3:
  //           nextPage(["tradingAddresses"]);
  //           break;
  //         case 4:
  //           nextPage([]);
  //           break;
  //         case 5:
  //           onSubmit();
  //           break;
  //         case 6:
  //           addTradingAddress();
  //           break;
  //         case 7:
  //           editTradingAddress();
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   },
  //   [page, nextPage, onSubmit, addTradingAddress, editTradingAddress]
  // );

  const actionsForPage = useCallback(
    (page) => {
      const actions = [];
      const nextPageFields = {
        0: ["country", "name", "type", "ABN", "ACN"],
        1: ["address", "suburb", "state", "postcode"],
        2: ["category", "description", "companySize"],
        3: ["tradingAddresses"],
      };
      if (pageDetails[page].back) {
        actions.push(
          <MKButton key={0} onClick={pageDetails[page].back}>
            Back
          </MKButton>
        );
      } else {
        actions.push(
          <MKButton key={0} onClick={handleClose}>
            Cancel
          </MKButton>
        );
      }
      if (pageDetails[page].next) {
        actions.push(
          <MKButton
            sx={{ py: { xs: "5px !important", sm: "10px" } }}
            key={1}
            onClick={() => nextPage(nextPageFields[page])}
          >
            Continue
          </MKButton>
        );
      } else {
        actions.push(
          <MKButton
            disabled={submitLoading}
            type="submit"
            key={1}
            onClick={pageDetails[page].onSubmit}
          >
            Submit
          </MKButton>
        );
      }
      return actions;
    },
    [nextPage, previousPage]
  );

  const pageComponents = [
    {
      component: (
        <Details
          errors={errorMessages}
          state={state}
          handleChange={handleChange}
          handleAnzsicCodeChange={handleAnzsicCodeChange}
        />
      ),
    },
    {
      component: <RegAddress errors={errorMessages} state={state} handleChange={handleChange} />,
    },
    {
      component: <Verify errors={errorMessages} state={state} handleChange={handleChange} />,
    },
    {
      component: (
        <TradingAddress
          prefillTradingAddress={prefillTradingAddress}
          deleteAddress={deleteTradingAddress}
          openEditTradingAddressPage={openEditTradingAddressPage}
          openNewTradingAddressPage={openNewTradingAddressPage}
          state={state}
          handleChange={handleChange}
          errors={errorMessages}
        />
      ),
    },
    {
      component: (
        <Summary errorMessages={errorMessages} companyDetails={state} handleChange={handleChange} />
      ),
    },
    {
      component: (
        <NewTradingAddress
          handleAddressPickerChange={handleAddressPickerChange}
          state={addressState}
          handleChange={handleTradingAddressChange}
        />
      ),
    },
    {
      component: (
        <EditTradingAddress
          handleAddressPickerChange={handleAddressPickerChange}
          state={addressState}
          handleChange={handleTradingAddressChange}
        />
      ),
    },
  ];

  const PageComponent = pageComponents[page]?.component;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // onKeyUp={handleKeyUp}
      sx={page === 5 ? { ".MuiPaper-root": { maxWidth: "700px" } } : {}}
    >
      {PageComponent} <DialogActions>{actionsForPage(page)}</DialogActions>
      {submitLoading && (
        <MKBox sx={{ height: "3.4rem", width: "100%", "margin-top": "-1rem", }}>
          <MKBox sx={{ height: "3.4rem", width: "3.4rem", "margin-right": "1.7rem", float: "right"}}>
            <Loading size={"2.5rem"} />
          </MKBox>
        </MKBox>)}
    </Dialog>
  );
};

export default AddCompanyModal;
