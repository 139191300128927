import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText } from "@mui/material";
import { Container } from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

import AddressPicker from "../../../../components/AddressPicker/AddressPicker";

const EditTradingAddress = ({
  handleAddressPickerChange,
  state,
  handleChange,
  containsCountry,
}) => {
  return (
    <>
      <DialogTitle sx={{ textAlign: "center", mt: 1, mb: 2 }}>Edit address</DialogTitle>
      <DialogContent>
        <Container>
          <AddressPicker
            containsCountry={containsCountry}
            handleAddressPickerChange={handleAddressPickerChange}
          />
          {containsCountry && (
            <div>
              <MKTypography variant="p">Country</MKTypography>
              <MKInput
                fullWidth
                size="small"
                sx={{ mb: 2, mt: "2px" }}
                value={state.country}
                onChange={handleChange("country")}
              />
            </div>
          )}
          <MKTypography variant="p">Street Address</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.address}
            onChange={handleChange("address")}
          />

          <MKTypography variant="p">Suburb</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.suburb}
            onChange={handleChange("suburb")}
          />

          <MKTypography variant="p">State</MKTypography>
          <MKInput
            fullWidth
            size="small"
            sx={{ mb: 2, mt: "2px" }}
            value={state.state}
            onChange={handleChange("state")}
          />

          <MKTypography variant="p">Postcode</MKTypography>
          <MKInput
            fullWidth
            size="small"
            value={state.postcode}
            onChange={handleChange("postcode")}
          />
        </Container>
      </DialogContent>
    </>
  );
};

export default EditTradingAddress;
