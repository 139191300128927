/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

import Settings from "pages/Settings";
import History from "pages/History";
import IdentityManagement from "pages/IdentityManagement";
import NotFound from "pages/NotFound";
import Upload from "pages/Upload";
import User from "pages/User";
import Role from "pages/Role";
import PermissionGate from "components/PermissionGate";
import SelectCompany from "./pages/SelectCompany";
import SignInBasicPage from "./pages/Authentication/SignIn/index";
import ForgotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import { AuthContext, AuthProvider } from "./AuthContext";
import MKBox from "./components/MKBox";
import { CircularProgress } from "@mui/material"; // Import AuthContext

const RequireAuth = ({ children }) => {
  let navigate = useNavigate();
  const { currentUser, isAuthInitialized } = useContext(AuthContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isAuthInitialized && !currentUser) {
        navigate("/sign-in");
      }
    }, 4000); // Adjust the delay as necessary

    return () => clearTimeout(timeout);
  }, [isAuthInitialized, currentUser, navigate]);

  if (!isAuthInitialized) {
    return (
      <MKBox sx={{ height: "100vh", width: "100vw" }}>
        <MKBox display="flex" sx={{ position: "absolute", top: "25%", width: "100%", pt: 2 }}>
          <CircularProgress sx={{ margin: "auto" }} size={50} />
        </MKBox>
      </MKBox>
    );
  }

  return children;
};
export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    // script.async = true;
    //
    // document.body.appendChild(script);
    //
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  const InitMap = () => {
    console.log("HELLO");
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <AuthProvider>
      {/* Wrap routes with AuthProvider */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route
            path="/upload"
            element={
              <RequireAuth>
                <PermissionGate>
                  <Upload />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth>
                <PermissionGate>
                  <Settings />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route
            path="/history"
            element={
              <RequireAuth>
                <PermissionGate>
                  <History />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route
            path="/user-access"
            element={
              <RequireAuth>
                <PermissionGate>
                  <IdentityManagement />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route
            path="/user-access/user/:userId"
            element={
              <RequireAuth>
                <PermissionGate>
                  <User />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route
            path="/user-access/role/:roleId"
            element={
              <RequireAuth>
                <PermissionGate>
                  <Role />
                </PermissionGate>
              </RequireAuth>
            }
          />
          <Route path="/select-company" element={<SelectCompany />} />
          <Route path="/404" element={<NotFound />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
          <Route path={"/user-auth-landing"} element={<ForgotPassword />} />

          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/sign-in" element={<SignInBasicPage />} />

          <Route
            path="/"
            element={
              <RequireAuth>
                <PermissionGate>
                  <Upload />
                </PermissionGate>
              </RequireAuth>
            }
          />
        </Routes>
      </ThemeProvider>{" "}
    </AuthProvider>
  );
}
