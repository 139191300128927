import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Loading from "../../components/Loading";

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import AddRole from "../../modals/Roles/addRole";
import { getGqlErrorMessage, loadSelectedCompanyId } from "../../common";

import { gql, useMutation } from "@apollo/client";
import { motion } from "framer-motion";
const slideInVariants = {
  hidden: { x: "-1vw", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};
export const ADD_ROLE_QUERY = gql`
  mutation AddRole($companyId: ID!, $input: RoleInput!) {
    addRole(companyId: $companyId, input: $input) {
      id
      name
      roles {
        id
        name
        description
        canUploadReceipts
        canUploadInvoices
        canAddUsers
        canEditUsers
        canAddRoles
        canEditRoles
        canAddVendors
        canViewUploadsForAllUsers
        roleImage
        canEditAppSettings
      }
    }
  }
`;
import { useRecoilValue, useSetRecoilState } from "recoil";
import { roleState, rolesState } from "../../atoms/company";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ErrorMsg from "../../components/ErrorMsg";

const iconMap = {
  Manager: <BusinessCenterIcon sx={{ width: "30px", height: "30px" }} />,
  Developer: <ComputerIcon sx={{ width: "30px", height: "30px" }} />,
  Support: <SupportAgentIcon sx={{ width: "30px", height: "30px" }} />,
  Engineer: <EngineeringIcon sx={{ width: "30px", height: "30px" }} />,
  Employee: <AccountCircleIcon sx={{ width: "30px", height: "30px" }} />,
  Logistics: <LocalShippingIcon sx={{ width: "30px", height: "30px" }} />,
  Maintenance: <CleaningServicesIcon sx={{ width: "30px", height: "30px" }} />,
  Retail: <StorefrontIcon sx={{ width: "30px", height: "30px" }} />,
};
const RolesList = ({ roleNumbers, loading }) => {
  const [addRole] = useMutation(ADD_ROLE_QUERY);

  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const role = useRecoilValue(roleState);
  const roles = useRecoilValue(rolesState);
  const setRoles = useSetRecoilState(rolesState);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRoleSubmit = (newRole) => {
    if (!role?.canAddRoles) return;
    setSubmitLoading(true);
    console.info("Add Role: " + JSON.stringify(newRole));
    setErrorMessage(null);
    const selectedCompanyId = loadSelectedCompanyId();

    addRole({
      variables: {
        companyId: selectedCompanyId,
        input: newRole,
      },
      onCompleted: (data) => {
        //TODO: update UI if needed
        console.info("New Role list: " + JSON.stringify(data.addRole.roles));
        setRoles(data.addRole.roles);
        handleClose();
        setSubmitLoading(false);
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to add the role: " + getGqlErrorMessage(error));
        setErrorMessage("Error: unable to add role. Please try again later");
        setSubmitLoading(false);
      },
    });
  };

  return (
    <>
      {errorMessage && <ErrorMsg errorMsg={errorMessage} />}
      <MKBox sx={{ px: { xs: 1, sm: 3 }, mt: { xs: 0, sm: 1 } }}>
        {role?.canAddRoles && (
          <MKButton onClick={handleOpen} color="primary" sx={{ mb: { xs: 1, sm: 2 }, mt: 2 }}>
            Add role
          </MKButton>
        )}
        <MKBox
          sx={{
            background: "#fff",
            p: loading ? 4 : 2,
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            width: "100%",
          }}
          mt={role?.canAddRoles ? 1 : 3}
        >
          {loading && <Loading size={40} />}

          {!loading && (
            <>
              <MKTypography
                sx={{
                  pb: 1,
                  color: "rgba(0, 0, 0, 0.6)",
                  mb: 2,
                  borderBottom: 1,
                  borderColor: "divider",
                  fontSize: 17,
                }}
              >
                Active roles ({roles?.length || 0})
              </MKTypography>
              <List
                sx={{
                  width: "100%",
                  maxHeight: { xs: "calc(100vh - 350px)", sm: "calc(100vh - 300px)" },

                  overflow: "auto",
                }}
                data-testid="role-list"
              >
                {roleNumbers &&
                  roles?.map((role) => {
                    let numOfPermission;

                    if (roleNumbers[role?.id]) {
                      numOfPermission = roleNumbers[role?.id];

                      if (numOfPermission === 1) numOfPermission = "(1) member";
                      else numOfPermission = `(${roleNumbers[role?.id]}) members`;
                    } else {
                      numOfPermission = `(0) members`;
                    }
                    return (
                      <motion.div
                        key={role?.id}
                        initial="hidden"
                        animate="visible"
                        variants={slideInVariants}
                        transition={{ duration: "0.2", type: "tween" }}
                      >
                        <ListItem>
                          <ListItemButton
                            onClick={() => navigate(`/user-access/role/${role?.id}`)}
                            sx={{
                              "&:hover": { opacity: "0.9" },
                              p: 2,
                              marginBottom: "1rem",
                              borderRadius: "8px",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                              alignItems: { xs: "flex-start", sm: "center" },
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <ListItemAvatar sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}>
                              <Avatar sx={{ width: "50px", height: "50px" }}>
                                {role?.roleImage && iconMap[role?.roleImage]}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              sx={{
                                mr: { xs: "0 !important", sm: 10 },
                                overflow: "hidden", // Handle overflow
                                ".MuiListItemText-primary": {
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                                  fontSize: { xs: "1rem", sm: "1.25rem" },
                                },
                                ".MuiListItemText-secondary": {
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: { xs: "100%", sm: "90%", md: "90%" },
                                  fontSize: { xs: "0.9rem", sm: "1.25rem" },
                                },
                              }}
                              primaryTypographyProps={{
                                style: {
                                  width: { xs: "100%", sm: "90%" },
                                },
                              }}
                              primary={role?.name}
                              secondary={role?.description}
                            />

                            <MKTypography
                              sx={{
                                flexShrink: 0,
                                marginRight: "1rem",
                                color: "rgba(0, 0, 0, 0.6)",
                                display: { xs: "none", sm: "inline" },
                              }}
                            >
                              {numOfPermission}
                            </MKTypography>
                          </ListItemButton>
                        </ListItem>
                      </motion.div>
                    );
                  })}
              </List>
            </>
          )}
        </MKBox>
        {roles && (
          <AddRole
            open={open}
            handleClose={handleClose}
            onSubmit={handleAddRoleSubmit}
            loading={submitLoading}
          />
        )}
      </MKBox>
    </>
  );
};

export default RolesList;
