import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../components/Loading";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MainContainer from "components/MainContainer/MainContainer";

import EditRole from "../../modals/Roles/editRole";
import DeleteModal from "../../modals/deleteModal";
import { getGqlErrorMessage, loadSelectedCompanyId } from "../../common";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { roleState, rolesState } from "../../atoms/company";

import { gql, useQuery, useMutation } from "@apollo/client";
import { Avatar, CircularProgress } from "@mui/material";

import ErrorMsg from "../../components/ErrorMsg";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ComputerIcon from "@mui/icons-material/Computer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import StorefrontIcon from "@mui/icons-material/Storefront";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

const iconMap = {
  Manager: <BusinessCenterIcon sx={{ width: "80px", height: "80px" }} />,
  Developer: <ComputerIcon sx={{ width: "80px", height: "80px" }} />,
  Support: <SupportAgentIcon sx={{ width: "80px", height: "80px" }} />,
  Engineer: <EngineeringIcon sx={{ width: "80px", height: "80px" }} />,
  Employee: <AccountCircleIcon sx={{ width: "80px", height: "80px" }} />,
  Logistics: <LocalShippingIcon sx={{ width: "80px", height: "80px" }} />,
  Maintenance: <CleaningServicesIcon sx={{ width: "80px", height: "80px" }} />,
  Retail: <StorefrontIcon sx={{ width: "80px", height: "80px" }} />,
};
export const ON_LOAD_QUERY = gql`
  query OnLoadQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      users {
        id

        role {
          id
          name
        }
      }
      roles {
        id
        name
        description
        canUploadReceipts
        canUploadInvoices
        canAddUsers
        canEditUsers
        canAddRoles
        canEditRoles
        canAddVendors
        canViewHistory
        canViewUploadsForAllUsers
        canEditAppSettings
        roleImage
      }
    }
  }
`;

export const UPDATE_ROLE_QUERY = gql`
  mutation UpdateRole($companyId: ID!, $roleId: ID!, $input: RoleInput!) {
    updateRole(companyId: $companyId, roleId: $roleId, input: $input)
  }
`;

export const DELETE_ROLE_QUERY = gql`
  mutation DeleteRole($companyId: ID!, $roleId: ID!) {
    deleteRole(companyId: $companyId, roleId: $roleId)
  }
`;

const GridItem = (props) => {
  const { label, value } = props;
  return (
    <Grid item xs={12} sm={6} md={4} key={label}>
      <FormControlLabel
        control={<Switch checked={value} disabled name={label} color="primary" />}
        label={label}
      />
    </Grid>
  );
};
const Role = () => {
  let navigate = useNavigate();

  const selectedCompanyId = loadSelectedCompanyId();
  const { loading, error, data } = useQuery(ON_LOAD_QUERY, {
    variables: {
      companyId: selectedCompanyId,
    },
  });

  const permissions = useRecoilValue(roleState);
  const setPermissions = useSetRecoilState(roleState);

  const roles = useRecoilValue(rolesState);
  const setRoles = useSetRecoilState(rolesState);

  const [updateRole] = useMutation(UPDATE_ROLE_QUERY);
  const [deleteRole] = useMutation(DELETE_ROLE_QUERY);

  const [role, setRole] = useState(null);
  const [roleNumbers, setRoleNumbers] = useState(null);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleOpenDelete = (e) => {
    if (roleNumbers !== 0) {
      e.target.blur();
      setErrorMessage(
        "Sorry, there are still users associated with this role. Please reassign them before deleting. "
      );
      setTimeout(() => setErrorMessage(""), 3000);
    } else {
      setOpenDelete(true);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!loading) {
      getRole();
    }
  }, [loading]);

  const getRole = () => {
    const roleId = window.location.href.split("/").filter(Boolean).pop();

    setRole(
      roles?.find((role) => role.id === roleId) ||
        data?.company?.roles?.find((role) => role.id === roleId)
    );

    const numbers = data?.company?.users?.reduce((acc, user) => {
      acc[user?.role?.id] = (acc[user?.role?.id] || 0) + 1;
      return acc;
    }, {});

    setRoleNumbers(numbers[roleId]);
    if (!roles) setRoles(data?.company?.roles);
  };

  const onEditRoleSubmit = (newRole) => {
    if (!permissions.canEditRoles) return;

    const roleId = newRole.id;
    delete newRole.id;
    delete newRole.__typename;
    setErrorMessage(null);
    setSubmitLoading(true);

    console.log(newRole);
    updateRole({
      variables: {
        companyId: selectedCompanyId,
        roleId: roleId,
        input: newRole,
      },
      onCompleted: (data) => {
        setRole({ ...newRole, id: roleId });
        const rolesArr = roles.map((role) => {
          if (roleId === role.id) return { ...newRole, id: roleId };
          else return role;
        });
        if (roleId === permissions.id) setPermissions({ ...newRole, id: roleId });
        setRoles(rolesArr);
        handleClose();
        setSubmitLoading(false);
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to update the role: " + getGqlErrorMessage(error));
        setErrorMessage("Error: unable to update the role. ");
        handleClose();
        setSubmitLoading(false);
      },
    });
  };

  const onDeleteRoleSubmit = () => {
    if (!permissions.canEditRoles) return;
    setErrorMessage(null);
    setSubmitLoading(true);

    deleteRole({
      variables: {
        companyId: selectedCompanyId,
        roleId: role.id,
      },
      onCompleted: (data) => {
        const roleId = role.id;
        const rolesArr = roles.filter((role) => role.id !== roleId);
        setRoles(rolesArr);
        handleClose();
        setSubmitLoading(false);

        navigate({
          pathname: "/user-access",
          search: "?roles=true",
        });
      },
      onError: (error) => {
        //TODO: update UI if needed
        console.error("Unable to delete the role: " + getGqlErrorMessage(error));
        setErrorMessage("Error: unable to delete the role. Please try again later. ");
        handleCloseDelete();
        setSubmitLoading(false);
      },
    });
  };

  return (
    <MainContainer title="Role">
      {errorMessage && <ErrorMsg errorMsg={errorMessage} />}

      <MKButton
        variant="contained"
        sx={{
          width: "110px",
          height: "40px",
          my: 2,
          mt: { xs: "10px", sm: 2 },
          ml: { xs: 2, sm: 3 },
          boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          ".MuiSvgIcon-root": { fontSize: "22px !important" },
        }}
        onClick={() =>
          navigate({
            pathname: "/user-access",
            search: "?roles=true",
          })
        }
      >
        <ArrowBackIcon fontSize={"large"} sx={{ mr: "10px" }} />
        <MKTypography fontSize="14px">Back</MKTypography>
      </MKButton>
      <MKBox sx={{ pr: "16px", pl: { xs: "16px", sm: "24px !important" } }}>
        <MKBox
          sx={{
            mb: 2,

            background: "#fff",
            boxShadow: " 0px 1px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            p: 4,
            maxWidth: "850px !important",
            ml: { xs: "auto", sm: "0 !important" },
          }}
        >
          {loading && <Loading size={40} />}
          {role && (
            <MKBox sx={{ display: "flex", flexDirection: "column" }}>
              <MKBox sx={{ width: "120px", mb: 2 }}>
                <Avatar sx={{ width: "140px", height: "140px" }}>
                  {role?.roleImage && iconMap[role?.roleImage]}
                </Avatar>
              </MKBox>
              <MKTypography mt={1} variant="h5">
                {role.name}
              </MKTypography>

              <MKTypography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontWeight: 400,
                }}
                mt={1}
                variant="h6"
              >
                {role.description}
              </MKTypography>

              {permissions.canEditRoles && role.name !== "Admin" && role.name !== "Employee" && (
                <MKBox
                  sx={{ display: "flex", mt: 2, width: "280px", justifyContent: "space-between" }}
                >
                  <MKButton color="primary" onClick={handleOpen}>
                    Edit Role
                  </MKButton>
                  <MKButton color="error" onClick={handleOpenDelete}>
                    Remove Role
                  </MKButton>
                </MKBox>
              )}
            </MKBox>
          )}
          {role && (
            <MKTypography mt={4} mb={2} variant="h5">
              Permissions:
            </MKTypography>
          )}
          {role && (
            <Grid container spacing={2}>
              <GridItem label={"Can Add Roles"} value={role.canAddRoles} />
              <GridItem label={"Can Add Users"} value={role.canAddUsers} />
              <GridItem label={"Can Add Vendors"} value={role.canAddVendors} />
              <GridItem label={"Can Edit Roles"} value={role.canEditRoles} />
              <GridItem label={"Can Edit Users"} value={role.canEditUsers} />
              <GridItem label={"Can Upload Invoices"} value={role.canUploadInvoices} />
              <GridItem label={"Can Upload Receipts"} value={role.canUploadReceipts} />
              <GridItem
                label={"Can View All Upload History"}
                value={role.canViewUploadsForAllUsers}
              />
              <GridItem label={"Can Edit Settings"} value={role.canEditAppSettings} />
            </Grid>
          )}
        </MKBox>
      </MKBox>
      {role && (
        <EditRole
          roleDetails={role}
          onSubmit={onEditRoleSubmit}
          open={open}
          handleClose={handleClose}
          loading={submitLoading}
        />
      )}
      <DeleteModal
        open={openDelete}
        onClose={handleCloseDelete}
        handleDelete={onDeleteRoleSubmit}
        message="Are you sure you want to delete this role? "
        loading={submitLoading}
      />
    </MainContainer>
  );
};

export default Role;
