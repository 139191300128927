import React, { useEffect, useState } from "react";

import { Dialog, DialogActions } from "@mui/material";
import MKButton from "components/MKButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogPaper: {
    width: "100%", // Adjust the width as needed,
    maxWidth: "500px",
  },
});
const AddCurrencyModal = ({ open, handleClose, onSubmit, currencyToEdit, loading }) => {
  const classes = useStyles();

  const [currency, setCurrency] = useState({
    name: "",
  });

  const [error, setError] = useState({
    name: false,
  });

  useEffect(() => {
    if (currencyToEdit) setCurrency({ name: currencyToEdit.label });
    else
      setCurrency({
        name: "",
      });
  }, [open]);

  const handleChange = (input) => (e) => {
    setCurrency({ ...currency, [input]: e.target.value });
  };

  const handleSubmit = (e) => {
    const errors = {
      name: false,
    };

    !currency.name ? (errors.name = true) : (errors.name = false);

    setError(errors);
    if (!currency.name) {
      return;
    }
    onSubmit(currency);
    handleClose();
  };

  return (
    <div>
      <Dialog
        onKeyUp={(e) => {
          const ENTER = 13;

          if (e.keyCode === ENTER) {
            handleSubmit(e);
          }
        }}
        disableRestoreFocus={true}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle>{currencyToEdit ? "Edit currency" : "Add new currency"}</DialogTitle>
        <DialogContent sx={{ pt: "5px !important" }}>
          <MKBox width={{ xs: "100%", sm: "450px" }}>
            <MKInput
              label={"Currency"}
              autoFocus
              fullWidth
              size="small"
              value={currency.name}
              onChange={handleChange("name")}
              error={error.name}
              helperText={error.name && "Required field. "}
            />
          </MKBox>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleClose}>Cancel</MKButton>
          <MKButton disabled={loading} sx={{ pl: 0, pr: 0 }} onClick={handleSubmit}>
            Submit
          </MKButton>{" "}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCurrencyModal;
